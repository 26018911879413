import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import InputIcon from '@material-ui/icons/Input';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Typography, Avatar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { PermIdentityRounded } from '@material-ui/icons';
import { logout } from 'src/actions/authActions';

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    drawerPaper: {
        maxHeight: 300,
        width: 450,
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(8)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(7),
            width: '100%'
        }
    },
    modalBackdrop: {
        backgroundColor: 'transparent'
    },
    header: {
        width: '100%',
        height: 75,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 3),
        paddingBottom: 5,
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2),
            paddingBottom: 3,
        }
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2)
        }
    },
    footer: {
        width: '100%',
        height: 75,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 2)
        }
    },
    companyNameContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'start',
        overflow: 'hidden'
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    userInfoContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(1, 0, 1, 2),
        wordBreak: 'break-all'
    },
    userInfoText: {
        display: 'flex',
        alignItems: 'flex-end',
        '& > svg': {
            height: 20,
            marginRight: 2
        }
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            width: 70,
            height: 70,
            fontSize: '2.25rem'
        },
        width: 90,
        height: 90,
        fontSize: '2.25rem'
    },
    logoutIcon: {
        marginRight: theme.spacing(1)
    }
}));

const ProfileDrawer = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { claims, userInitials } = useSelector((state) => state.auth);

    const logoutClick = () => {
        onClose();
        dispatch(logout());
    };

    return (
        <Drawer
            ModalProps={{
                BackdropProps: {
                    className: classes.modalBackdrop
                }
            }}
            classes={{
                paper: classes.drawerPaper
            }}
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <div className={classes.drawerContainer}>
                <Paper className={classes.header}>
                    <div className={classes.companyNameContainer}>
                        <Typography variant="h5">SmartDialog Admin</Typography>
                        <Typography variant="body2">{process.env.REACT_APP_BUILDID}</Typography>
                    </div>
                </Paper>
                <div className={classes.content}>
                    <div className={classes.avatarContainer}>
                        <Avatar className={classes.avatar}>{userInitials}</Avatar>
                    </div>
                    <div className={classes.userInfoContainer}>
                        <Typography className={classes.userInfoText} variant="h4" gutterBottom>{claims?.name}</Typography>
                        <Typography className={classes.userInfoText} variant="subtitle2">
                            <PermIdentityRounded />
                            {claims?.sub ?? ''}
                        </Typography>
                    </div>
                </div>
                <Paper className={classes.footer}>
                    <div />
                    <Button
                        className={classes.logoutButton}
                        color="inherit"
                        onClick={logoutClick}
                    >
                        <InputIcon className={classes.logoutIcon} />
                        Sign out
                    </Button>
                </Paper>
            </div>
        </Drawer>
    );
};

ProfileDrawer.propTypes = {
    open: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired
};

export default ProfileDrawer;