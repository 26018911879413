import React from 'react';
import { Route, useLocation, matchPath } from 'react-router-dom';
import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';
import { definitions } from './HelpContent';
import HelpContentBody from './HelpContentBody';
import NotFound from './HelpContent/NotFound.md';

const useStyles = makeStyles((theme) => ({
    drawer: ({ open }) => ({
        display: open ? 'block' : 'none'
    }),
    drawerPaper: {
        position: 'relative',
        width: 500
    },
    bottomNavBarContainer: {
        backgroundColor: theme.palette.background.gray,
        color: theme.palette.white,
        position: 'fixed',
        bottom: 0,
        width: '100%'
    }
}));

const HelpDrawer = ({ open = false, setOpen = () => { } }) => {
    const classes = useStyles({ open });
    const { pathname } = useLocation();
    const match = definitions.some((hc) => matchPath(pathname, { path: hc.path, exact: hc.exact }));
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div style={{ margin: '10px', whiteSpace: 'break-spaces' }}>
                {definitions.map((help) => (
                    <Route key={help.path} path={help.path} exact={help.exact}>
                        <HelpContentBody content={help.content} />
                    </Route>
                ))}
                {!match ? (
                    <HelpContentBody content={NotFound} />
                ) : null}
            </div>
            <div className={classes.bottomNavBarContainer}>
                <IconButton color="inherit" onClick={() => setOpen(false)}>
                    <MenuRounded />
                </IconButton>
            </div>
        </Drawer>
    );
};

export default HelpDrawer;