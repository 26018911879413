import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
    Drawer,
    Divider,
    List,
    ListSubheader,
    Hidden,
    colors
} from '@material-ui/core';
import NavItem from 'src/components/NavItem';
import navBarTheme from 'src/layout/navBarTheme';
import ScrollableContent from 'src/components/ScrollableContent';
import navConfig from 'src/navConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.gray
    },
    mobileDrawer: {
        width: theme.constants.navbarWidth,
    },
    desktopDrawer: {
        width: theme.constants.navbarWidth,
        top: 64,
        height: theme.constants.contentHeight
    },
    navigation: {
        overflow: 'auto',
        padding: theme.spacing(0, 1, 1, 1),
        flexGrow: 1
    },
    profile: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    badge: {
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
    },
    badgeDot: {
        height: 9,
        minWidth: 9
    },
    onlineBadge: {
        backgroundColor: colors.green[600]
    },
    awayBadge: {
        backgroundColor: colors.orange[600]
    },
    busyBadge: {
        backgroundColor: colors.red[600]
    },
    offlineBadge: {
        backgroundColor: colors.grey[300]
    },
    avatar: {
        cursor: 'pointer',
        width: 40,
        height: 40
    },
    details: {
        marginLeft: theme.spacing(2)
    },
    moreButton: {
        marginLeft: 'auto',
        color: colors.blueGrey[200]
    },
    bottomNavBarContainer: {
        width: '100%',
        display: 'inline-block',
        minHeight: 64
    },
    devider: {
        color: '#fff !important'
    }
}));

function renderNavItems({
    items, subheader, key, ...rest
}) {
    return (
        <List key={key}>
            {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
            {items.reduce(
                // eslint-disable-next-line no-use-before-define
                (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc, pathname, item, depth = 0
}) {
    if (item.items) {
        let open = false;

        if (item.linkable) {
            open = item.items.some((subItem) => matchPath(pathname, {
                path: subItem.href,
                exact: false
            }));
        } else {
            open = matchPath(pathname, {
                path: item.href,
                exact: false
            });
        }

        acc.push(
            <NavItem
                disabled={Boolean(item.disabled) || item.items.every((childItem) => childItem?.disabled)}
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={item.href}
                label={item.label}
                open={Boolean(open) && !item.disabled}
                title={item.title}
                titleComponent={item.titleComponent}
                linkable={item.linkable}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    key: item.href
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                disabled={Boolean(item.disabled)}
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={item.href}
                label={item.label}
                title={item.title}
                titleComponent={item.titleComponent}
            />
        );
    }

    return acc;
}

const NavBar = ({
    openMobile,
    onMobileClose,
    className,
    ...rest
}) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Divider className={classes.divider} />
            <nav className={classes.navigation}>
                <ScrollableContent>
                    {navConfig.map((item) => (
                        renderNavItems({
                            items: item.items,
                            subheader: item.subheader,
                            pathname: location.pathname,
                            key: item.subheader,
                            linkable: item.linkable
                        })
                    ))}
                </ScrollableContent>
            </nav>
            <Divider className={classes.divider} />
            <div className={classes.bottomNavBarContainer}>
                {/* <BottomNavBar /> */}
            </div>
        </div>
    );

    return (
        <ThemeProvider theme={navBarTheme}>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.mobileDrawer
                    }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{
                        paper: classes.desktopDrawer
                    }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </ThemeProvider>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;