import React from 'react';
import { useHttpGetRequest } from '../../../utils/httpClient';

const CustomerStateContext = React.createContext();
const CustomerDispatchContext = React.createContext();

const defaultState = {
    activeTab: 'info',
    activeTabId: null,
    azureMarketplaceSubscription: null,

    messagingAccounts: [],

    selectedMessagingAccountLoading: false,
    selectedCustomer: null,
    selectedMessagingAccountUpdated: false,
    messagingAccountsChanged: false,

    canDeleteCustomer: true,
    canInactivateCustomer: true,

    countries: [],
    countryOptions: [],

    routeTables: [],

    customerAddressTypes: [],
    contactInformationTypes: [],

    getCustomerByIdLoading: false,

    messagingServicesUpdated: false,
    carrierBillingServicesUpdated: false,

    messagingTemplatesTablePaging: {
        take: 10,
        sortField: 'name',
        sortOrder: 'asc',
        top: 0,
        page: 1
    },
    refreshCustomers: false
};

function customerReducer(state, action) {
    switch (action.type) {
        case 'setActiveTab': {
            return { ...state, activeTab: action.data };
        }
        case 'setSelectedCustomer': {
            return { ...state, selectedCustomer: action.data, azureMarketplaceSubscription: null };
        }
        case 'setAzureMarketplaceSubscription': {
            return { ...state, azureMarketplaceSubscription: action.data };
        }
        case 'messagingAccountDeleted': {
            return { ...state, messagingAccountsChanged: true };
        }
        case 'messagingAccountsChanged': {
            return { ...state, messagingAccountsChanged: action.data || true };
        }
        case 'setMessagingAccounts': {
            return { ...state, messagingAccounts: action.data, messagingAccountsChanged: true };
        }
        case 'selectedMessagingAccountUpdated': {
            return { ...state, selectedMessagingAccountUpdated: !state.selectedMessagingAccountUpdated };
        }
        case 'setCountries': {
            return {
                ...state,
                countries: action.data,
                countryOptions: action.data.map((country) => { return { label: country.name, value: country.id }; })
            };
        }
        case 'setCustomerAddressTypes': {
            return {
                ...state,
                customerAddressTypes: Object.keys(action.data).map((key) => { return { label: action.data[key], value: action.data[key] }; })
            };
        }
        case 'setContactInformationTypes': {
            return {
                ...state,
                contactInformationTypes: Object.keys(action.data).map((key) => { return { label: action.data[key], value: action.data[key] }; })
            };
        }
        case 'setRouteTables': {
            return {
                ...state,
                routeTables: action.data
            };
        }
        case 'getCustomerByIdLoading': {
            return {
                ...state,
                getCustomerByIdLoading: action.data
            };
        }
        case 'setMessagingServicesUpdated': {
            return {
                ...state,
                messagingServicesUpdated: Boolean(action?.data)
            };
        }
        case 'setCarrierBillingServicesUpdated': {
            return {
                ...state,
                carrierBillingServicesUpdated: Boolean(action?.data)
            };
        }
        case 'updateMessagingTemplatesTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                messagingTemplatesTablePaging: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        case 'setSelectedMessagingAccountLoading': {
            return {
                ...state,
                selectedMessagingAccountLoading: Boolean(action?.data)
            };
        }
        case 'refreshCustomers': {
            return {
                ...state,
                refreshCustomers: Boolean(action?.data)
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const CustomerContextProvider = ({ children }) => {
    const [state, updateContext] = React.useReducer(customerReducer, defaultState);
    return (
        <CustomerStateContext.Provider value={state}>
            <CustomerDispatchContext.Provider value={updateContext}>
                {children}
            </CustomerDispatchContext.Provider>
        </CustomerStateContext.Provider>
    );
};

function useCustomerState() {
    const context = React.useContext(CustomerStateContext);
    if (context === undefined) {
        throw new Error('useCustomerState must be used within a CustomerContextProvider');
    }
    return context;
}

function useCustomerDispatch() {
    const context = React.useContext(CustomerDispatchContext);
    if (context === undefined) {
        throw new Error('useCountDispatch must be used within a CountProvider');
    }
    return context;
}

const useGetCountries = () => {
    return useHttpGetRequest({
        method: 'GET',
        endpoint: 'https://api.arena.fi/customer-management/v1/country'
    });
};

export {
    CustomerContextProvider,
    useCustomerState,
    useCustomerDispatch,

    useGetCountries
};