import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { momentDateFormatter } from '../../../components/BootstrapTable/ColumnFormatters';

const brokerTableColumns = [
    {
        dataField: 'displayName',
        text: 'Display Name',
        hidden: false,
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        hidden: false,
        sort: true
    },
    {
        dataField: 'description',
        text: 'Description',
        hidden: false,
        sort: true
    }
];

const connectorTableColumns = [

    {
        dataField: 'isEnabled',
        text: 'Status',
        hidden: false,
        sort: false,
        formatter: (cell) => {
            if (cell) {
                return (
                    <span>
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                        {' '}
                        Running
                    </span>
                );
            }
            return (
                <span>
                    <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                    {' '}
                    Stopped
                </span>
            );
        }
    },
    {
        dataField: 'id',
        text: 'Id',
        hidden: false,
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        hidden: false,
        sort: true
    },
    {
        dataField: 'appName',
        text: 'App Name',
        hidden: false,
        sort: true
    },
    {
        dataField: 'resourceGroup',
        text: 'Resource Group',
        hidden: false,
        sort: true
    },
    {
        dataField: 'created',
        text: 'Created',
        hidden: false,
        sort: true,
        formatter: momentDateFormatter
    },
    {
        dataField: 'updated',
        text: 'Updated',
        hidden: false,
        sort: true,
        formatter: momentDateFormatter
    },
    {
        dataField: 'brokerId',
        text: 'Broker Id',
        hidden: false,
        sort: true
    }
];

const messagingTemplatesTableColumns = [
    {
        dataField: 'id',
        text: 'Id',
        sort: true
    },
    {
        dataField: 'customerId',
        text: 'Customer',
        sort: true
    },
    {
        dataField: 'displayName',
        text: 'Display Name',
        sort: true
    },
    {
        dataField: 'state',
        text: 'State',
        sort: true
    },
    {
        dataField: 'type',
        text: 'Type',
        sort: true
    },
    {
        dataField: 'language',
        text: 'Language',
        sort: true
    }
];

export {
    brokerTableColumns,
    connectorTableColumns,
    messagingTemplatesTableColumns
};