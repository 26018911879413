import React from 'react';
import { withStyles } from '@material-ui/styles';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import { Typography, Grid, Button, Card, CardHeader } from '@material-ui/core';
import { trackException } from 'src/utils/appInsights';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { v4 as UuidV4 } from 'uuid';

const styles = (theme) => ({
    container: {
        flex: 1,
        width: '100%',
        height: '100%'
    },
    errorIcon: {
        'font-size': 60
    },
    errorText: {
        marginBottom: 10
    },
    formControl: {
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        padding: 0,
        paddingBottom: 0
    },
    reportForm: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    reportSubmitted: {
        padding: theme.spacing(2),
        display: 'flex'
    }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            correlationId: UuidV4(),
            hasError: false
        };
    }

    static getDerivedStateFromError(/* error */) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, /* errorInfo */) {
        // You can also log the error to an error reporting service
        const { correlationId } = this.state;
        trackException(error, { correlationId });
    }

    // eslint-disable-next-line class-methods-use-this
    refreshPage() {
        window.location.reload();
    }

    render() {
        const { classes, children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Grid
                    className={classes.container}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Card>
                        <CardHeader
                            avatar={
                                <SentimentDissatisfiedIcon className={classes.errorIcon} />
                            }
                            title={(<Typography variant="h3">Oops something went wrong!</Typography>)}
                            subheader={(
                                <>
                                    <Typography variant="h5" className={classes.errorText}>Try refreshing the page or submit an error report</Typography>
                                    <Button variant="contained" color="primary" onClick={this.refreshPage} style={{ marginRight: 10 }}>Refresh Page</Button>
                                </>
                            )}
                        />
                    </Card>
                </Grid>
            );
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object
};

const mapStateToProps = (state) => {
    return { claims: state?.auth?.claims ?? {} };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(ErrorBoundary)));