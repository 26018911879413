import React from 'react';
import { Redirect } from 'react-router-dom';

const Dashboard = React.lazy(() => import('./scenes/Dashboard'));
const Customers = React.lazy(() => import('./scenes/Customers/components/Customers'));
const DeletedCustomers = React.lazy(() => import('./scenes/DeletedCustomers'));
const Countries = React.lazy(() => import('./scenes/Countries'));
const Users = React.lazy(() => import('./scenes/Users/components/Users'));
const MessagingLog = React.lazy(() => import('./scenes/MessagingLog/components/MessagingLog'));
const AuditLog = React.lazy(() => import('./scenes/AuditLog'));

const MessagingBrokers = React.lazy(() => import('./scenes/Messaging/Brokers'));
const MessagingConnectors = React.lazy(() => import('./scenes/Messaging/Connectors'));
const RoutingTables = React.lazy(() => import('./scenes/Messaging/Routing'));
const Opcheck = React.lazy(() => import('./scenes/Messaging/Opcheck'));
const PricingTables = React.lazy(() => import('./scenes/Messaging/PricingTables'));
const AdBan = React.lazy(() => import('./scenes/Messaging/AdBan'));
const InputSources = React.lazy(() => import('./scenes/Messaging/InputSources'));
const Templates = React.lazy(() => import('./scenes/Messaging/Templates'));
const MessageBlocking = React.lazy(() => import('./scenes/Messaging/MessageBlocking'));
const Kannel = React.lazy(() => import('./scenes/Messaging/Kannel'));

const ReportingConfigurations = React.lazy(() => import('./scenes/Reporting/components/Configurations/Configurations'));
const ReportingCountries = React.lazy(() => import('./scenes/Reporting/components/Countries/Countries'));
const Reports = React.lazy(() => import('./scenes/Reporting/components/Reports/Reports'));
const PremiumBrokerPricingExceptions = React.lazy(() => import('./scenes/Reporting/components/PremiumBrokerPricingExceptions/PremiumBrokerPricingExceptions'));

const RedeemableCodes = React.lazy(() => import('./scenes/Services/RedeemableCodes'));

const NewsAndAlerts = React.lazy(() => import('./scenes/NewsAndAlerts'));
const Swagger = React.lazy(() => import('./scenes/Utils/Swagger'));
const ConfigGeneration = React.lazy(() => import('./scenes/Utils/ConfigGeneration'));
const AccountAuthentication = React.lazy(() => import('./scenes/Utils/AccountAuthentication'));
const OpaaliCredentials = React.lazy(() => import('./scenes/Utils/OpaaliCredentials'));

const ProductBundles = React.lazy(() => import('./scenes/AccessControl/ProductBundles'));
const Purchases = React.lazy(() => import('./scenes/Purchases'));

const ErrorBoundaryTest = React.lazy(() => import('./components/ErrorBoundaryTest'));

const LowerCaseRoute = (props) => {
    const path = props.location.pathname;
    return <Redirect to={`${path.toLowerCase()}`} />;
};

const routes = [
    { path: '/:slug1*:slug2([A-Z]):slug3*/', exact: true, sensitive: true, component: LowerCaseRoute },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },

    { path: '/customermanagement/customers/:customerId?', exact: false, name: 'Customers', component: Customers },
    { path: '/customermanagement/deletedcustomers', exact: false, name: 'Deleted Customers', component: DeletedCustomers },
    { path: '/customermanagement/countries', exact: false, name: 'Countries', component: Countries },
    { path: '/customermanagement/purchases/:instanceId?', exact: false, name: 'Purchases', component: Purchases },
    { path: '/customermanagement/users/:userId?/:tab?', exact: false, name: 'Users', component: Users },

    { path: '/log', exact: true, name: 'Logging', component: () => <Redirect to="/log/messages" /> },
    { path: '/log/messages', exact: true, name: 'Message Log', component: MessagingLog },
    { path: '/log/audit', exact: true, name: 'Audit Log', component: AuditLog },

    { path: '/messagingmanagement', exact: true, name: 'Messaging Management', component: () => <Redirect to="/messagingmanagement/brokers" /> },
    { path: '/messagingmanagement/brokers', exact: true, name: 'Messaging Brokers', component: MessagingBrokers },
    { path: '/messagingmanagement/connectors', exact: true, name: 'Messaging Connectors', component: MessagingConnectors },
    { path: '/messagingmanagement/routingtables/:id?/:tab?', exact: false, name: 'Routing Tables', component: RoutingTables },
    { path: '/messagingmanagement/opcheck', exact: false, name: 'OpCheck', component: Opcheck },
    { path: '/messagingmanagement/pricingtables', exact: false, name: 'Pricing Tables', component: PricingTables },
    { path: '/messagingmanagement/adban', exact: false, name: 'AdBan', component: AdBan },
    { path: '/messagingmanagement/inputsources', exact: false, name: 'Input Sources', component: InputSources },
    { path: '/messagingmanagement/templates/:templateId?', exact: false, name: 'Messaging Templates', component: Templates },
    { path: '/messagingmanagement/messageblocking/:type?', exact: false, name: 'Message Blocking', component: MessageBlocking },
    { path: '/messagingmanagement/kannel', exact: false, name: 'Kannel', component: Kannel },

    { path: '/reporting', exact: true, name: 'Reporting', component: () => <Redirect to="/reporting/configurations" /> },
    { path: '/reporting/configurations/:customerId?/:tabId?', exact: false, name: 'Reporting Configurations', component: ReportingConfigurations },
    { path: '/reporting/countries/:countryId?', exact: true, name: 'Reporting Countries', component: ReportingCountries },
    { path: '/reporting/reports', exact: false, name: 'Reports', component: Reports },
    { path: '/reporting/premiumbrokerpricingmt', exact: false, name: 'Premium Broker Pricing', component: PremiumBrokerPricingExceptions },

    { path: '/services/redeemablecodes', exact: false, name: 'Redeemable Codes', component: RedeemableCodes },

    { path: '/news-alerts', exact: false, name: 'News & Alerts', component: NewsAndAlerts },

    { path: '/utils/swagger/:selectedSwagger?', exact: false, name: 'Swagger', component: Swagger },
    { path: '/utils/configgeneration', exact: false, name: 'Config Generation', component: ConfigGeneration },
    { path: '/utils/accountauthentication/:tab?', exact: false, name: 'Account Authentication', component: AccountAuthentication },
    { path: '/utils/opaalicredentials', exact: false, name: 'Opaali Credentials', component: OpaaliCredentials },

    { path: '/useraccess/productbundles', exact: false, name: 'Product Bundles', component: ProductBundles },

    { path: '/errortest', exact: false, name: 'Error', component: ErrorBoundaryTest },

    // Redirect to dashboard for other nondefined routes
    { path: '*', component: () => <Redirect to="/dashboard" /> }
];

export default routes;
