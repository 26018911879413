import JwtDecode from 'jwt-decode';
import { AccessTokenRefreshed } from 'src/actions/authActions';

const getUserInitials = (claims) => {
    const _initials = [
        (claims?.given_name?.substring(0, 1) ?? null),
        (claims?.family_name?.substring(0, 1) ?? null)
    ];

    if (_initials.every((val) => val)) {
        return _initials;
    }

    return claims?.email?.substring(0, 1)?.toUpperCase() ?? 'SD';
};

const initialState = {
    userInitials: null,
    accessToken: null,
    claims: null
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case AccessTokenRefreshed: {
            const claims = JwtDecode(action?.data);
            return {
                ...state,
                accessToken: action?.data ?? null,
                userInitials: getUserInitials(claims),
                claims: action?.data ? JwtDecode(action?.data) : null,
                displayName: claims?.name ?? ''
            };
        }
        default:
            return state;
    }
};

export default reducer;