export default {
    MuiStepLabel: {
        active: {
            fontWeight: '600 !important',
        },
        label: {
            fontSize: 16
        }
    }
};
