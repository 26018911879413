import React from 'react';
import { sortByProperty } from 'src/utils/arrayFunctions';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const defaultState = {
    selectedUser: null,
    usersLoaded: false,
    usersLoading: true,
    userLoading: false,
    users: [],
    userOptions: [],
    refreshUsers: false,

    userListPagination: {
        take: 10,
        sortField: null,
        sortOrder: null,
        top: 0,
        page: 1
    },
    userCustomerListPagination: {
        take: 10,
        sortField: null,
        sortOrder: null,
        top: 0,
        page: 1
    }
};

function UserReducer(state, action) {
    switch (action.type) {
        case 'usersLoading': {
            return {
                ...state,
                usersLoading: action.data
            };
        }
        case 'initUsers': {
            return {
                ...state,
                usersLoaded: true,
                users: action.data?.filter((u) => u?.profile?.signInEmail) ?? [],
                userOptions: action.data
                    ?.filter?.((u) => u?.profile?.signInEmail)
                    .map((u) => ({ label: u?.profile?.signInEmail ?? u?.externalUserId, value: u?.externalUserId }))
                    .sort(sortByProperty('label')) ?? []
            };
        }
        case 'userLoading': {
            return {
                ...state,
                userLoading: action.data
            };
        }
        case 'userSelected': {
            return {
                ...state,
                selectedUser: action.data
            };
        }
        case 'refreshUsers': {
            return {
                ...state,
                refreshUsers: !state.refreshUsers
            };
        }
        case 'updateUserListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                userListPagination: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        case 'updateUserCustomerListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                userCustomerListPagination: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const UserContextProvider = ({ children }) => {
    const [state, updateContext] = React.useReducer(UserReducer, defaultState);
    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={updateContext}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

function useUserState() {
    const context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
}

function useUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return context;
}

export {
    UserContextProvider,
    useUserState,
    useUserDispatch
};