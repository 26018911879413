import React from 'react';
import { sortByProperty } from 'src/utils/arrayFunctions';
import { useHttpGetRequest } from 'src/utils/httpClient';

const MetadataStateContext = React.createContext();

const defaultState = {
    enums: {
        typeOfNumber: [],
        ipRestrictionType: []
    }
};

const MetadataContextProvider = ({ children }) => {
    const [state, setState] = React.useState(defaultState);

    const { payload } = useHttpGetRequest({ method: 'GET', endpoint: 'https://api.arena.fi/arena-applications-web-pulse-api/v1/metadata/enums' });

    React.useEffect(() => {
        if (payload) {
            const newState = { ...defaultState };

            Object.keys(payload).forEach((key) => {
                newState.enums[key] = Object.keys(payload[key]).map((val) => ({ label: payload[key][val], value: val })).sort(sortByProperty('label'));
            });
            setState(newState);
        }
    }, [payload]);

    return (
        <MetadataStateContext.Provider value={state}>
            {children}
        </MetadataStateContext.Provider>
    );
};

function useMetadataState() {
    const context = React.useContext(MetadataStateContext);
    if (context === undefined) {
        throw new Error('useMetadataState must be used within a MetadataProvider');
    }
    return context;
}

export {
    MetadataContextProvider,
    useMetadataState
};