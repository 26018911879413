import { booleanFormatter } from 'src/components/BootstrapTable/ColumnFormatters';

const countriesColumns = [
    {
        dataField: 'id',
        text: 'Id',
        sort: true,
        hidden: true
    },
    {
        dataField: 'countryCode',
        text: 'Country Code',
        sort: true
    },
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'countryPrefix',
        text: 'Country Prefix',
        sort: true
    },
    {
        dataField: 'global',
        text: 'Global',
        sort: true
    },
    {
        dataField: 'sendingRequiresPricing',
        text: 'Sending Requires Pricing',
        sort: true,
        formatter: booleanFormatter
    }
];

const useGetPremiumBrokerPricingExceptionsColumns = (brokers) => [
    {
        dataField: 'id',
        text: 'Id',
        sort: true,
        hidden: true
    },
    {
        dataField: 'brokerId',
        text: 'Broker',
        sort: true,
        formatter: (brokerId) => {
            return brokers?.find?.((item) => item?.value === brokerId)?.label ?? brokerId;
        }
    },
    {
        dataField: 'pricings',
        text: 'Pricings #',
        sort: true,
        formatter: (cell) => cell?.length ?? 0
    }
];

export { countriesColumns, useGetPremiumBrokerPricingExceptionsColumns };