import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ServiceWorkerUpdateAvailable } from 'src/actions/appActions';
import useTabHasFocus from 'src/utils/useTabHasFocus';

const ServiceWorkerProvider = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const tabHasFocus = useTabHasFocus();
    const [serviceWorkerRegistration, setServiceWorkerRegistration] = React.useState(null);

    React.useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                setServiceWorkerRegistration(registration);

                // Dispatch ServiceWorkerUpdateAvailable if serviceworker onUpdate method somehow got lost
                if (registration.waiting && registration.waiting.state === 'installed') {
                    dispatch(ServiceWorkerUpdateAvailable());
                }

                // SW has been updated in other tab
                registration.active.addEventListener('statechange', (e) => {
                    if (e.target.state === 'redundant') {
                        window.location.reload();
                    }
                });
            });
        }
    }, [dispatch]);

    // Check for serviceworker updated on navigation and focus on tab
    React.useEffect(() => {
        if (tabHasFocus && serviceWorkerRegistration && serviceWorkerRegistration.update) {
            serviceWorkerRegistration.update();
        }
    }, [pathname, tabHasFocus]);

    return null;
};

export default ServiceWorkerProvider;