import { LinearProgress, makeStyles } from '@material-ui/core';
import React, { Suspense } from 'react';
import ModalContainer from 'react-modal-promise';
import ErrorBoundary from 'src/components/ErrorBoundary';
import ScrollableContent from 'src/components/ScrollableContent';
import HelpDrawer from 'src/help/HelpDrawer';
import NavBar from 'src/layout/NavBar';
import TopBar from 'src/layout/TopBar';

const useStyles = makeStyles((theme) => ({
    content: {
        position: 'fixed',
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        maxWidth: '100%',
        overflowX: 'hidden',
        height: theme.constants.contentHeight,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.constants.navbarWidth
        },
        [theme.breakpoints.up('sm')]: {
            top: 64
        },
        [theme.breakpoints.down('xs')]: {
            top: 56
        }
    },
    authLayoutContainer: {
        height: '100vh',
        display: 'flex',
        overflow: 'hidden',
        '@media all and (-ms-high-contrast:none)': {
            height: 0 // IE11 fix
        }
    }
}));

const MainLayout = ({ children }) => {
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = React.useState(false);
    const [helpDrawerOpen, setHelpDrawerOpen] = React.useState(false);

    return (
        <>
            <ModalContainer style={{ zIndex: 9999 }} />
            <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} setHelpDrawerOpen={setHelpDrawerOpen} helpDrawerOpen={helpDrawerOpen} />
            <NavBar
                onMobileClose={() => setOpenNavBarMobile(false)}
                openMobile={openNavBarMobile}
            />
            <div className={classes.authLayoutContainer}>
                <Suspense fallback={<LinearProgress />}>
                    <div className={classes.content}>
                        <ScrollableContent style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                            <ErrorBoundary>
                                {children}
                            </ErrorBoundary>
                        </ScrollableContent>
                        <HelpDrawer open={helpDrawerOpen} setOpen={setHelpDrawerOpen} />
                    </div>
                </Suspense>
            </div>
        </>
    );
};

export default MainLayout;