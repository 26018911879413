import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { TelemetryProvider } from 'src/utils/appInsights';
import { Provider as StoreProvider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'src/theme';
import 'src/assets/scss/main.scss';
import { CssBaseline } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import ServiceWorkerProvider from 'src/ServiceWorkerProvider';
import { msalInstance } from 'src/auth/PublicClientApplication';
import { AuthContextProvider } from 'src/auth';
import routes from 'src/routes';
import MainLayout from 'src/layout/MainLayout';
import Page from 'src/components/Page';
import moment from 'moment';
import store from './store';
import Contexts from './Contexts';
import ToastContainer from './components/Toast/Toast';
import 'bootstrap/dist/css/bootstrap.min.css';

moment.locale('fi', {
    week: {
        dow: 1
    }
});

const history = createBrowserHistory();

const App = () => {
    return (
        <>
            <StoreProvider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Router history={history}>
                            <ServiceWorkerProvider />
                            <TelemetryProvider>
                                <AuthContextProvider msalInstance={msalInstance}>
                                    <Contexts>
                                        <MainLayout>
                                            <Switch>
                                                {routes.map((route) => {
                                                    return route.component ? (
                                                        <Route
                                                            key={route.path}
                                                            route={route}
                                                            path={route.path}
                                                            exact={route.exact}
                                                            name={route.name}
                                                            sensitive={route.sensitive}
                                                            render={(props) => (
                                                                <Page>
                                                                    <route.component {...props} />
                                                                </Page>
                                                            )}
                                                        />
                                                    ) : (null);
                                                })}
                                            </Switch>
                                        </MainLayout>
                                    </Contexts>
                                </AuthContextProvider>
                            </TelemetryProvider>
                        </Router>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StoreProvider>
            <ToastContainer />
        </>
    );
};

export default App;
