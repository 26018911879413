import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from 'src/auth/authConfig';
import { msalInstance } from 'src/auth/PublicClientApplication';

export const AccessTokenRefreshed = 'AccessTokenRefreshed';

export const refreshAccessToken = () => async (dispatch) => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.[0]) {
        const request = {
            account: accounts[0],
            scopes: loginRequest.scopes
        };

        try {
            const response = await msalInstance.acquireTokenSilent(request);
            dispatch({ type: AccessTokenRefreshed, data: response.accessToken });
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request);
            }
            if (error instanceof BrowserAuthError) {
                // fallback to interaction when silent call fails
                return msalInstance.acquireTokenRedirect(request);
            }
        }
    }
    return null;
};

export const logout = () => () => {
    msalInstance.logoutRedirect();
};