import React from 'react';
import { countriesColumns } from './columnDefinitions';

const CountryStateContext = React.createContext();
const CountryDispatchContext = React.createContext();

const defaultState = {
    columnDefinitions: countriesColumns,

    tablePaging: {
        take: 10,
        sortField: 'name',
        sortOrder: 'asc',
        top: 0,
        page: 1
    }
};

function countryReducer(state, action) {
    switch (action.type) {
        case 'updateCountryPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                tablePaging: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const CountryContextProvider = ({ children }) => {
    const [state, updateContext] = React.useReducer(countryReducer, defaultState);
    return (
        <CountryStateContext.Provider value={state}>
            <CountryDispatchContext.Provider value={updateContext}>
                {children}
            </CountryDispatchContext.Provider>
        </CountryStateContext.Provider>
    );
};

function useCountriesState() {
    const context = React.useContext(CountryStateContext);
    if (context === undefined) {
        throw new Error('useCountriesState must be used within a CountryProvider');
    }
    return context;
}

function useCountryDispatch() {
    const context = React.useContext(CountryDispatchContext);
    if (context === undefined) {
        throw new Error('useCountryDispatch must be used within a CountryProvider');
    }
    return context;
}

export {
    CountryContextProvider,
    useCountriesState,
    useCountryDispatch
};