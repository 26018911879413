import React from 'react';
import { Avatar, Badge, IconButton } from '@material-ui/core';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    badgeConnected: {
        '& > .MuiBadge-badge': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.main
        }
    }
}));

const ProfileButton = ({ onClick }) => {
    const classes = useStyles();
    const { userInitials } = useSelector((state) => state.auth);
    const { connected } = useSelector((state) => state.signalR);

    return (
        <IconButton
            color="inherit"
            onClick={onClick}
        >
            <Badge
                className={connected ? classes.badgeConnected : ''}
                overlap="circular"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                variant="dot"
            >
                <Avatar>{userInitials}</Avatar>
            </Badge>
        </IconButton>
    );
};

ProfileButton.propTypes = {
    onClick: propTypes.func.isRequired
};

export default ProfileButton;