const key = `SmartDialog_Admin_${window.location.host}_ApplicationUpdated`;

const initialState = {
    serviceWorkerUpdated: localStorage.getItem(key) === 'true'
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'SW_UPDATE_AVAILABLE':
            localStorage.setItem(key, true);
            return {
                ...state,
                serviceWorkerUpdated: true
            };
        case 'SW_UPDATED': {
            localStorage.setItem(key, false);
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        }
        default:
            return state;
    }
};

export default reducer;