import React from 'react';
import './AuthenticationStatusPage.css';

const AuthenticationStatusPage = ({ children }) => {
    return (
        <div className="splash-screen">
            {children}
            {!children && <div className="loading-dot">.</div>}
        </div>
    );
};

export default AuthenticationStatusPage;
