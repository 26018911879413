import { createTheme } from '@material-ui/core';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import constants from './constants';

const baseTheme = {
    palette,
    typography,
    overrides,
    constants
};

export const theme = createTheme(baseTheme);
