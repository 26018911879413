import Dashboard from './Dashboard.md';
import Customers from './Customers/Customers.md';
import CustomerCountries from './Customers/CustomerCountries.md';
import CustomerInfo from './Customers/CustomerInfo.md';
import CustomerUsers from './Customers/CustomerUsers.md';
import CustomerProducts from './Customers/CustomerProducts.md';
import MessagingAccounts from './Customers/MessagingAccounts.md';
import CustomerServices from './Customers/CustomerServices.md';
import CustomerInputSources from './Customers/CustomerInputSources.md';
import CustomerTemplates from './Customers/CustomerTemplates.md';
import Users from './Users/Users.md';
import UserInfo from './Users/UserInfo.md';
import UserCustomers from './Users/UserCustomers.md';
import Templates from './Messaging/Templates/Templates.md';
import ReportingDefinitions from './Reporting/Definitions';

export default [
    { path: '/dashboard', content: Dashboard },
    { path: '/customermanagement/customers', content: Customers, exact: true },
    { path: '/customermanagement/customers/:customerId/info', content: CustomerInfo },
    { path: '/customermanagement/customers/:customerId/users', content: CustomerUsers },
    { path: '/customermanagement/customers/:customerId/products', content: CustomerProducts },
    { path: '/customermanagement/customers/:customerId/services', content: CustomerServices },
    { path: '/customermanagement/customers/:customerId/inputsources', content: CustomerInputSources },
    { path: '/customermanagement/customers/:customerId/messagingtemplates', content: CustomerTemplates },

    { path: '/customermanagement/countries', content: CustomerCountries, exact: true },

    { path: '/customermanagement/customers/:customerId/messagingaccounts', content: MessagingAccounts },

    { path: '/customermanagement/users', content: Users, exact: true },
    { path: '/customermanagement/users/:userid/info', content: UserInfo },
    { path: '/customermanagement/users/:userid/customers', content: UserCustomers },

    { path: '/messagingmanagement/templates', content: Templates },

    ...ReportingDefinitions
];