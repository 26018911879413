import {
    JsonHubProtocol,
    HubConnectionBuilder,
    LogLevel
} from '@microsoft/signalr';
import { trackEvent } from 'src/utils/appInsights';
import logger from 'src/utils/logger';
import { refreshAccessToken } from 'src/actions/authActions';

const isDev = process.env.NODE_ENV === 'development';
const LOGLEVEL = isDev ? LogLevel.Warning : LogLevel.Error;

const signalREndpoint = 'https://api.arena.fi/Arena-Applications-SmartDialog-RealTime/v1/smartdialog-admin';

export const actions = {
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    RECONNECTING: 'RECONNECTING',
    DISCONNECTED: 'DISCONNECTED',
    ERROR: 'ERROR',
    CONNECTION_STOPPED: 'CONNECTION_STOPPED',
    SUBSCRIBING_TO_EVENT: 'SUBSCRIBING_TO_EVENT'
};

const startSignalRConnection = (connection) => async (dispatch) => {
    try {
        await connection.start();
        logger.info('SignalR connection established');
        dispatch({ type: actions.CONNECTED, data: { connection } });
        return connection;
    } catch (err) {
        logger.error('SignalR Connection Error: ', err);
        trackEvent('SignalR Connection Error', err);
        dispatch({ type: actions.ERROR });
        // setTimeout(() => startSignalRConnection(connection), 5000);
    }
    return null;
};

export const stopSignalRConnection = () => async (dispatch, getState) => {
    const { signalR: { connection } } = getState();

    if (connection && connection.stop) {
        try {
            dispatch({ type: actions.CONNECTION_STOPPED });
            await connection.stop();
            logger.info('SignalR connection stopped');
        } catch (err) {
            logger.error('SignalR error stopping connection: ', err);
        }
    }

    return null;
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const buildSignalRConnection = () => async (dispatch, getState) => {
    const { auth: { claims } } = getState();

    const options = {
        logMessageContent: isDev,
        logger: LOGLEVEL,
        accessTokenFactory: () => {
            const { auth: { accessToken } } = getState();
            return accessToken;
        },
        headers: {
            'Ocp-Apim-Subscription-Key': claims?.sysAdminSubscriptionKey ?? ''
        }
    };
    // create the connection instance
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed
    const hubConnection = new HubConnectionBuilder()
        .withUrl(signalREndpoint, options)
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LOGLEVEL)
        .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    hubConnection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    hubConnection.onclose(() => {
        /* logger.error('Connection closed due to error. Try refreshing this page to restart the connection', error);
        trackEvent('SignalR connection closed due to error', error); */
        dispatch({ type: actions.ERROR });
        dispatch(stopSignalRConnection());
    });

    hubConnection.onreconnecting((error) => {
        logger.error('Connection lost due to error. Reconnecting.', error);

        if (error && error.indexOf && error.indexOf('401') > 0) {
            dispatch(refreshAccessToken());
        }
        dispatch({ type: actions.RECONNECTING });
    });

    hubConnection.onreconnected((connectionId) => {
        logger.info('Connection reestablished. Connected with connectionId', connectionId);
        dispatch({ type: actions.CONNECTED });
    });

    return dispatch(startSignalRConnection(hubConnection));
};

export const signalREventSubscribe = (eventName, callback) => async (dispatch, getState) => {
    const { signalR: { connection } } = getState();

    let resolvedConnection;

    if (!connection) {
        const connectionPromise = dispatch(buildSignalRConnection());
        dispatch({ type: actions.CONNECTING, connection: connectionPromise });
        resolvedConnection = await connectionPromise;
    } else if (connection && connection.then) {
        resolvedConnection = await connection;
    } else if (connection) {
        resolvedConnection = connection;
    }

    if (resolvedConnection) {
        logger.info(`Subscribing to SignalR event ${eventName}`);
        resolvedConnection.on(eventName, (eventData) => {
            logger.info(`Recieved ${eventName} event`, eventData);
            return callback(eventData, dispatch);
        });
    } else {
        logger.error(`Error subscribing to event "${eventName}", could not get SignalR connection`);
    }
};

export const signalREventUnsubscribe = (eventName) => async (dispatch, getState) => {
    const { signalR: { connection } } = getState();

    if (connection && connection.off) {
        logger.info(`Unsubscribing from SignalR event ${eventName}`);
        connection.off(eventName);
    }
};