import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createModal } from 'react-modal-promise';

const ConfirmModal = ({
    open = true,
    close = () => { },
    title = <div>Modal Title</div>,
    Content = () => <div>Body</div>,

    okButtonText = 'Save',
    okButtonColor = 'primary',
    cancelButtonText = 'Cancel',
    cancelButtonColor = 'secondary'
}) => {
    return (
        <Modal isOpen={open} onExit={() => close(null)} zIndex={9999}>
            <ModalHeader toggle={() => close(null)}>{title}</ModalHeader>
            <ModalBody>
                <Content />
            </ModalBody>
            {okButtonText === '' && cancelButtonText === '' ? (null) : (
                <ModalFooter>
                    {cancelButtonText !== '' ? (
                        <Button color={cancelButtonColor} onClick={() => { close(null); }}>{cancelButtonText}</Button>
                    ) : (null)}
                    {okButtonText !== '' ? (
                        <Button color={okButtonColor} onClick={() => { close(true); }}>{okButtonText}</Button>
                    ) : (null)}
                </ModalFooter>
            )}
        </Modal>
    );
};

const createModalPromise = async (props) => {
    const modal = createModal(ConfirmModal, {});
    try {
        const result = await modal(props);
        if (result) {
            return result;
        }
        return null;
    } catch (error) {
        return null;
    }
};

export default createModalPromise;