import moment from 'moment';

/**
 * ## Usage:
 * average([{prop: 1}, {prop: 2}])((p) => p.prop)
 *
 * Will calulate the average for property "prop"
 */
export const average = (array) => (selectorFunction) => {
    const _initial = { count: 0, total: 0 };

    const { total, count } = array?.reduce((prev, current) => ({
        count: prev.count + 1,
        total: prev.total + selectorFunction(current)
    }), _initial) ?? _initial;

    return total / count;
};

/**
 * ## Usage:
 * min([{prop: 1}, {prop: 2}])((p) => p.prop)
 *
 * Will calulate the min for property "prop"
 */
export const min = (array) => (selectorFunction) => {
    const _initial = 99999999;
    return array?.reduce((prev, current) => {
        const value = selectorFunction(current);
        if (value < prev) {
            return value;
        }
        return prev;
    }, _initial) ?? 0;
};

/**
 * ## Usage:
 * max([{prop: 1}, {prop: 2}])((p) => p.prop)
 *
 * Will calulate the max for property "prop"
 */
export const max = (array) => (selectorFunction) => {
    const _initial = 0;
    return array?.reduce((prev, current) => {
        const value = selectorFunction(current);
        if (value > prev) {
            return value;
        }
        return prev;
    }, _initial) ?? _initial;
};

/**
 * ## Usage:
 * sum([{prop: 1}, {prop: 2}])((p) => p.prop)
 *
 * Will calulate the sum for property "prop"
 */
export const sum = (array) => (selectorFunction) => {
    const _initial = 0;
    return array?.reduce((prev, current) => (prev + selectorFunction(current)), _initial) ?? _initial;
};

export const sortByProperty = (property) => {
    let sortOrder = 1;
    let _property = property;

    if (_property[0] === '-') {
        sortOrder = -1;
        _property = _property.substr(1);
    }
    return (a, b) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result;

        if (a[_property] < b[_property]) {
            result = -1;
        } else if (a[_property] > b[_property]) {
            result = 1;
        } else {
            result = 0;
        }
        return result * sortOrder;
    };
};

export const sortBySelector = (selectorFunction, direction) => {
    if (typeof selectorFunction !== 'function') {
        throw new Error('First argument is not a function in sortBySelector');
    }

    const sortOrder = direction?.toLowerCase() === 'desc' ? 1 : -1;

    return (left, right) => {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result;

        const leftValue = selectorFunction(left);
        const rightValue = selectorFunction(right);

        if (leftValue < rightValue) {
            result = -1;
        } else if (leftValue > rightValue) {
            result = 1;
        } else {
            result = 0;
        }
        return result * sortOrder;
    };
};

export const sortByDate = (direction) => {
    const sortOrder = direction?.toLowerCase() === 'desc' ? 1 : -1;

    return (a, b) => {
        let result;

        const aDate = new Date(a).getTime();
        const bDate = new Date(b).getTime();

        if (aDate < bDate) {
            result = -1;
        } else if (aDate > bDate) {
            result = 1;
        } else {
            result = 0;
        }
        return result * sortOrder;
    };
};

export const sortByDateProperty = (property, direction) => {
    const sortOrder = direction?.toLowerCase() === 'desc' ? 1 : -1;

    return (a, b) => {
        let result;

        const aDate = new Date(a[property]).getTime();
        const bDate = new Date(b[property]).getTime();

        if (aDate < bDate) {
            result = -1;
        } else if (aDate > bDate) {
            result = 1;
        } else {
            result = 0;
        }
        return result * sortOrder;
    };
};

/**
 * ## Usage:
 * groupByTimePeriod([{date: '2020-10-01T23:51:28Z'}, {date: '2020-10-01T23:51:28Z'}], 'date', 'day|week|month|year', (d) => { moment(d).toJSON() })
 *
 * Will group the array by time
 */
export const groupByTimePeriod = (obj, timestamp, period, keyFormatter) => {
    const resp = {};

    for (let i = 0; i < obj.length; i++) {
        let d = moment(obj[i][timestamp]);

        switch (period) {
            case 'day':
                d = d.startOf('day');
                break;
            case 'week':
                d = d.startOf('week');
                break;
            case 'month':
                d = d.startOf('month');
                break;
            case 'year':
                d = d.startOf('year');
                break;
            default:
                break;
        }

        let groupKey = d?.toJSON() ?? 'Unknown';

        if (keyFormatter && typeof keyFormatter === 'function') {
            groupKey = keyFormatter(d) ?? 'Unknown';
        }
        resp[groupKey] = resp[groupKey] || [];
        resp[groupKey].push(obj[i]);
    }
    return resp;
};

/**
 * ## Usage:
 * groupByProperty([{serviceId: '...guid...', name: 'Service Name 1'}, {serviceId: '...guid...', name: 'Service Name 2'}], (service) => `${service.name}`)
 *
 * Will group the array by what keyFormatter returns
 */
export const groupByProperty = (array, keyFormatter) => {
    const resp = {};

    for (let i = 0; i < array.length; i++) {
        if (keyFormatter && typeof keyFormatter === 'function') {
            const groupKey = keyFormatter(array[i]);
            if (typeof groupKey === 'string' || typeof groupKey === 'number') {
                resp[groupKey] = resp[groupKey] || [];
                resp[groupKey].push(array[i]);
            }
        }
    }

    return resp;
};

export const replace = (selectorFunction, replaceValue) => {
    if (typeof selectorFunction !== 'function') { throw new Error('First argument is not a function in replace'); }

    return (all, current) => {
        if (selectorFunction(current)) { return replaceValue && typeof replaceValue === 'object' ? [...all, { ...current, ...replaceValue }] : [...all]; }
        return [...all, current];
    };
};

export const replaceEntityByKey = (key, collection) => (newEntity) => {
    const index = collection.findIndex((mf) => mf.key === key);
    const _collection = [...collection];

    if (index > -1) { _collection.splice(index, 1, { ...newEntity }); }

    return _collection;
};