import React from 'react';
import PropTypes from 'prop-types';

const ScrollableContent = ({ id = `scrollable-content-${Math.floor(Math.random() * 10)}`, children, style, containerRef, className }) => {
    const initRef = (ref) => {
        if (typeof containerRef === 'function') {
            containerRef(ref);
        }
    };

    return (
        <div id={id} ref={initRef} className={className} style={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto' }}>
            <div style={style}>
                {children}
            </div>
        </div>
    );
};

ScrollableContent.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    style: PropTypes.object,
    containerRef: PropTypes.func,
    className: PropTypes.string
};

export default ScrollableContent;