import React from 'react';
import moment from 'moment';
import { useQueryParam } from 'src/utils/queryParameters';

const MessageLogFilterContext = React.createContext();

export const FilterColumnNames = {
    CreatedFrom: 'CreatedFrom',
    CreatedTo: 'CreatedTo',
    Sent: 'Sent',
    SourceServiceId: 'SourceServiceId',
    SourceServiceType: 'SourceServiceType',
    ServiceId: 'ServiceId',
    Direction: 'Direction',
    Type: 'Type',
    ServiceType: 'ServiceType',
    SenderAddress: 'SenderAddress',
    Recipient: 'Recipient',
    SendStatus: 'SendStatus',
    Content: 'Content',
    DeliveryReason: 'DeliveryReason',
    DeliveryStatus: 'DeliveryStatus',
    DeliveryPercentage: 'DeliveryPercentage',
    PriorityMessage: 'PriorityMessage',
    MessagingAccountId: 'MessagingAccountId',
    CustomerId: 'CustomerId',
    BrokerId: 'BrokerId',
    CustomerData: 'CustomerData',
    MessageId: 'MessageId'
};

const filterExpressions = {
    [FilterColumnNames.CreatedFrom]: (value) => `Created ge ${value}`,
    [FilterColumnNames.CreatedTo]: (value) => `Created le ${value}`,
    [FilterColumnNames.ServiceId]: (value) => `(ServiceId eq ${value} or SourceServiceId eq ${value})`,
    [FilterColumnNames.Direction]: (value) => `Direction eq '${value}'`,
    [FilterColumnNames.Type]: (value) => `Type eq '${value}'`,
    [FilterColumnNames.ServiceType]: (value) => `ServiceType eq '${value}'`,
    [FilterColumnNames.SenderAddress]: (value) => `SenderAddress eq '${value}'`,
    [FilterColumnNames.Recipient]: (value) => `Recipients/any(recipient: recipient/Address eq '${value}')`,
    [FilterColumnNames.SendStatus]: (value) => `SendStatus eq '${value}'`,
    [FilterColumnNames.Content]: (value) => `Contains(Content,'${value}')`,
    [FilterColumnNames.DeliveryReason]: (value) => `Recipients/any(recipient: recipient/reason eq '${value}')`,
    [FilterColumnNames.DeliveryStatus]: (value) => `Recipients/any(recipient: ${value !== '' ? value?.map((s) => `recipient/status eq '${s}'`).join(' or ') : ''})`,
    [FilterColumnNames.DeliveryPercentage]: (value) => (value === '< 100' ? 'Recipients/any(recipient: recipient/status ne \'Delivered\')' : 'Recipients/all(recipient: recipient/status eq \'Delivered\')'),
    [FilterColumnNames.PriorityMessage]: () => 'IsPriorityMessage eq true',
    [FilterColumnNames.MessagingAccountId]: (value) => `MessagingAccountId eq ${value}`,
    [FilterColumnNames.CustomerId]: (value) => `CustomerId eq ${value}`,
    [FilterColumnNames.BrokerId]: (value) => `Recipients/any(recipient: recipient/brokerId eq ${value})`,
    [FilterColumnNames.CustomerData]: (value) => `Contains(CustomerData,'${value}')`,
    [FilterColumnNames.MessageId]: (value) => `Id eq ${value}`
};

const getDefaultValues = () => ({
    // Default to null if nothing else added
    ...Object.keys(FilterColumnNames).reduce((columns, columnName) => ({ ...columns, [columnName]: null }), {}),
    // Set custom default values
    [FilterColumnNames.CreatedFrom]: moment().subtract(1, 'hour').toJSON()
});

const maxDurationWithoutCustomerSelected = moment.duration(6, 'hours').asMilliseconds();
const maxDurationWithCustomerSelected = moment.duration(30, 'days').asMilliseconds();

const validateFromAndTo = (from, to, customerId, recipientAddress, messageId) => {
    // Allow any dates when filtering on messageId
    if (messageId && messageId !== '') {
        return true;
    }

    // Allow any dates when filtering on recipient
    if (recipientAddress && recipientAddress !== '') {
        return true;
    }

    const maxDuration = customerId ? maxDurationWithCustomerSelected : maxDurationWithoutCustomerSelected;
    const fromMoment = moment(from);
    const toMoment = moment(to);

    // Fromdate needs to be selected
    if (!fromMoment.isValid()) {
        return false;
    }

    // Allow selecting only fromdate if within maxDuration
    if (!toMoment.isValid() && fromMoment.isBefore(moment().subtract(maxDuration))) {
        return false;
    }

    // fromdate must be before todate
    if (fromMoment.isAfter(toMoment)) {
        return false;
    }

    const diff = toMoment.diff(fromMoment);
    if (diff > maxDuration) {
        return false;
    }

    return true;
};

const toQueryHash = (input) => {
    return Buffer.from(JSON.stringify(input)).toString('base64');
};

const parseQueryHashToObject = (input) => {
    if (typeof input === 'string') {
        try {
            const json = Buffer.from(input, 'base64').toString();
            const filterObject = JSON.parse(json);

            if (typeof filterObject === 'object') {
                return filterObject;
            }
            return {};
        } catch (error) {
            return {};
        }
    }
    return {};
};

const MessagingLogFilterContext = ({ children }) => {
    const { value } = useQueryParam('q');
    const [initialQueryHash] = React.useState(value);

    const [filters, _setFilters] = React.useState({
        ...getDefaultValues(),
        ...parseQueryHashToObject(initialQueryHash)
    });

    const validateAndSetFilters = React.useCallback((filters) => {
        _setFilters((currentFilters) => {
            let newFilters;
            if (typeof filters === 'function') {
                newFilters = filters(currentFilters);
            } else if (typeof filters === 'object') {
                newFilters = filters;
            } else {
                return currentFilters;
            }

            if (!newFilters[FilterColumnNames.CustomerId]) {
                newFilters[FilterColumnNames.ServiceId] = null;
            }

            const createdFrom = moment(newFilters[FilterColumnNames.CreatedFrom]);
            const createdTo = moment(newFilters[FilterColumnNames.CreatedTo]);

            if (validateFromAndTo(
                newFilters[FilterColumnNames.CreatedFrom],
                newFilters[FilterColumnNames.CreatedTo],
                newFilters[FilterColumnNames.CustomerId],
                newFilters[FilterColumnNames.Recipient],
                newFilters[FilterColumnNames.MessageId]
            )) {
                return {
                    ...newFilters,
                    [FilterColumnNames.CreatedFrom]: createdFrom.toJSON(),
                    [FilterColumnNames.CreatedTo]: createdTo.toJSON()
                };
            }

            // Set from to default value if both are cleared
            if (!createdFrom.isValid() && !createdTo.isValid()) {
                const { [FilterColumnNames.CreatedFrom]: defaultCreatedFrom } = getDefaultValues();
                return {
                    ...newFilters,
                    [FilterColumnNames.CreatedFrom]: defaultCreatedFrom,
                    [FilterColumnNames.CreatedTo]: null
                };
            }
            const maxDuration = newFilters[FilterColumnNames.CustomerId] ? maxDurationWithCustomerSelected : maxDurationWithoutCustomerSelected;

            // Use todate and subtract the maximum duration to get fromdate
            if (!createdFrom.isValid()) {
                return {
                    ...newFilters,
                    [FilterColumnNames.CreatedFrom]: moment(createdTo).subtract(maxDuration).toJSON(),
                    [FilterColumnNames.CreatedTo]: createdTo.toJSON()
                };
            }

            // Use fromdate and add maximum duration to get todate
            return {
                ...newFilters,
                [FilterColumnNames.CreatedFrom]: createdFrom.toJSON(),
                [FilterColumnNames.CreatedTo]: moment(createdFrom).add(maxDuration).toJSON()
            };
        });
    }, [_setFilters]);

    const setFilterValue = React.useCallback((fieldName, value) => validateAndSetFilters((filters) => ({ ...filters, [fieldName]: value }), []));

    const getFilterValue = React.useCallback((fieldName) => {
        if (typeof filters?.[fieldName] === 'undefined' || filters?.[fieldName] === null) {
            return [];
        }
        if (filters[fieldName] === '') {
            return [];
        }

        switch (fieldName) {
            case FilterColumnNames.CreatedFrom:
            case FilterColumnNames.CreatedTo:
                return [moment(filters[fieldName])];
            default:
                return [filters[fieldName]];
        }
    }, [filters]);

    const filterExpression = React.useMemo(() => {
        return Object.keys(filterExpressions).reduce((expressions, column) => {
            if (filters?.[column] && filters?.[column] !== '') {
                const columnExpression = filterExpressions[column](filters[column]);
                return [...expressions, columnExpression];
            }
            return expressions;
        }, []).join(' and ');
    }, [filters]);

    const filterDialogApplyFilters = React.useCallback((applyNewFilters, columns) => (event) => {
        event.currentTarget.blur();

        const filterList = applyNewFilters();

        const _filters = columns.reduce((filters, column, index) => {
            const columnFilterName = column?.filterName;

            if (!columnFilterName) {
                return filters;
            }

            const filterValue = filterList?.[index]?.[0];
            if (!filterValue || filterValue === '') {
                return { ...filters, [columnFilterName]: null };
            }
            return {
                ...filters,
                [columnFilterName]: filterValue
            };
        }, {});
        validateAndSetFilters(_filters);
    }, []);

    const filterDialogResetFilters = React.useCallback((applyNewFilters, columns) => (event) => {
        event.currentTarget.blur();

        const _filters = columns.reduce((filters, column) => {
            const columnFilterName = column?.filterName;

            if (!columnFilterName) {
                return filters;
            }
            return { ...filters, [columnFilterName]: null };
        }, {});

        validateAndSetFilters(_filters);
        applyNewFilters();
    }, []);

    const clearFilters = React.useCallback(() => {
        const clearedFilters = Object.keys(FilterColumnNames).reduce((filters, columnName) => ({ ...filters, [columnName]: null }), {});
        validateAndSetFilters(clearedFilters);
    }, []);

    const setFilters = React.useCallback((filters) => {
        if (typeof filters === 'object') {
            validateAndSetFilters((currentFilters) => {
                return Object.keys(filters)
                    .filter((filterName) => FilterColumnNames[filterName])
                    .reduce((newFilters, filterName) => ({ ...newFilters, [filterName]: filters[filterName] }), currentFilters);
            });
        }
    }, [validateAndSetFilters]);

    return (
        <MessageLogFilterContext.Provider
            value={{
                filters,
                setFilterValue,
                getFilterValue,
                filterExpression,
                filterDialogApplyFilters,
                filterDialogResetFilters,
                clearFilters,
                setFilters
            }}
        >
            {children}
        </MessageLogFilterContext.Provider>
    );
};

export const useMessagingLogFilterContext = () => {
    const context = React.useContext(MessageLogFilterContext);
    if (context === undefined) {
        throw new Error('useMessagingLogFilterContext must be used within a MessagingLogFilterContext');
    }
    return context;
};

export const useStoreMessageLogFilterInQuery = () => {
    const { filters } = useMessagingLogFilterContext();
    const { set } = useQueryParam('q');

    React.useEffect(() => {
        // Update query param when filters change
        set(toQueryHash(filters));
        return () => {
            // Clear queryparam when navigating away
            set(null);
        };
    }, [filters]);
};

export default MessagingLogFilterContext;