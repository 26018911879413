import React from 'react';
import { ToastContainer as DefaultToastContainer, toast as reactToastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as UuidV4 } from 'uuid';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

const defaultOptions = {
    position: reactToastify.POSITION.BOTTOM_RIGHT,
    hideProgressBar: false
};

export const toast = {
    info: (content, options = {}) => {
        reactToastify.info(content, {
            ...defaultOptions,
            className: 'bg-info',
            ...options
        });
    },
    success: (content, options = {}) => {
        reactToastify.success(content, {
            ...defaultOptions,
            className: 'bg-success',
            ...options
        });
    },
    warn: (content, options = {}) => {
        reactToastify.warn(content, {
            ...defaultOptions,
            className: 'bg-warning',
            ...options
        });
    },
    error: (fullContent, id, options = {}) => {
        const errorToastClick = (e) => {
            e.preventDefault();

            ConfirmModal({
                Content: () => <span style={{ whiteSpace: 'break-spaces' }}>{fullContent}</span>,
                title: <div>Error Info</div>,
                okButtonText: 'Close',
                okButtonColor: 'primary',
                cancelButtonText: ''
            }).then(() => {

            }).catch(() => {

            });
        };

        const ContentWrapper = ({ content, closeToast }) => {
            return <div onClick={(e) => errorToastClick(e, closeToast)}>{content}</div>;
        };

        const toastId = id || UuidV4();
        if (fullContent && fullContent.substring) {
            const content = fullContent.substring(0, 200);

            if (!reactToastify.isActive(toastId)) {
                reactToastify.error(<ContentWrapper content={content} />, {
                    ...defaultOptions,
                    ...options,
                    toastId,
                    className: 'bg-danger',
                    closeOnClick: false
                });

                const log = JSON.parse(localStorage.getItem('arena-pulse-admin-log')) || [];
                log.push({
                    time: new Date(),
                    message: fullContent
                });
                localStorage.setItem('arena-pulse-admin-log', JSON.stringify(log));
            }
        }
    }
};

const ToastContainer = () => {
    return <DefaultToastContainer />;
};

export default ToastContainer;