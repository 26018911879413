import { useCallback, useEffect, useRef } from 'react';

/* const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
} */

const useInterval = (callback, timeout = 0) => {
    const timeoutIdRef = useRef();

    const cancel = useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearInterval(timeoutId);
        }
    }, [timeoutIdRef]);

    useEffect(() => {
        if (timeout) {
            timeoutIdRef.current = setInterval(callback, timeout);
        } else {
            cancel();
        }
        return cancel;
    }, [callback, timeout, cancel]);

    return cancel;
};

export default useInterval;