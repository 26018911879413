import { Component } from 'react';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router-dom';
import { ai } from './TelemetryService';

class TelemetryProvider extends Component {
    constructor() {
        super();
        this.state = {
            initialized: false
        };
    }

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const AppInsightsInstrumentationKey = process.env.REACT_APP_APPINSIGHTS_KEY;
        if (!initialized && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({ initialized: true });
        }
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

export default withRouter(withAITracking(ReactPlugin, TelemetryProvider));