import React from 'react';
import { HttpClientProvider } from 'src/utils/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { AccessTokenRefreshed } from 'src/actions/authActions';
import AuthenticationStatusPage from 'src/auth/AuthenticationStatusPage';
import { InteractionStatus, InteractionRequiredAuthError, InteractionType, BrowserAuthError } from '@azure/msal-browser';
import { MsalProvider, useMsal, useMsalAuthentication, useIsAuthenticated } from '@azure/msal-react';
import useAccessToken from 'src/auth/useAccessToken';
import useInterval from 'src/utils/useInterval';
import { Typography } from '@material-ui/core';
import { loginRequest } from './authConfig';

const checkRefreshTokenIntervalMs = 60000;

const IsAuthenticated = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const { login, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const isAuthenticated = useIsAuthenticated();
    const accessToken = useAccessToken(loginRequest.scopes);
    const { accessToken: stateAccessToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (error && error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else if (error instanceof BrowserAuthError) {
            login(InteractionType.Redirect, loginRequest);
        }
    }, [error, login]);

    React.useEffect(() => {
        if (accessToken) {
            dispatch({ type: AccessTokenRefreshed, data: accessToken });
        }
    }, [dispatch, accessToken]);

    // Call acquireTokenSilent every checkRefreshTokenIntervalMs to refresh accesstoken automatically in the background
    useInterval(async () => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            const request = {
                account: accounts[0],
                scopes: loginRequest.scopes
            };
            instance.acquireTokenSilent(request).then((response) => {
                if (response.accessToken !== stateAccessToken) {
                    dispatch({ type: AccessTokenRefreshed, data: response.accessToken });
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request);
                }
                if (error instanceof BrowserAuthError) {
                    return instance.acquireTokenRedirect(request);
                }
                return null;
            });
        }
    }, checkRefreshTokenIntervalMs);

    if (isAuthenticated && stateAccessToken) {
        return (
            <HttpClientProvider>
                {children}
            </HttpClientProvider>
        );
    }

    if (error && !(error instanceof InteractionRequiredAuthError)) {
        return (
            <AuthenticationStatusPage>
                <Typography variant="h3">{error?.errorCode ?? ''}</Typography>
                <Typography variant="body1">{error?.errorMessage ?? ''}</Typography>
            </AuthenticationStatusPage>
        );
    }

    return <AuthenticationStatusPage />;
};

const AuthContextProvider = ({ children, msalInstance }) => {
    return (
        <MsalProvider instance={msalInstance}>
            <IsAuthenticated>
                {children}
            </IsAuthenticated>
        </MsalProvider>
    );
};

export default AuthContextProvider;