import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

const MarkDownContainer = styled.div`
    ul,ol {
        margin-left: 17px;
    }
`;

const HelpContentBody = ({ content }) => {
    const [htmlContent, setHtmlContent] = React.useState('');

    React.useEffect(() => {
        const fetchContent = async () => {
            const file = await fetch(content);
            const text = await file.text();
            setHtmlContent(text);
        };
        fetchContent();
    }, [content]);

    return (
        <MarkDownContainer>
            <ReactMarkdown
                remarkPlugins={[[remarkGfm]]}
                components={{
                    a: (props) => <a {...props} target="_blank">{props.children}</a>
                }}
            >
                {htmlContent}
            </ReactMarkdown>
        </MarkDownContainer>
    );
};

export default HelpContentBody;