import palette from '../palette';

export default {
    root: {
        fontSize: '1rem'
    },
    input: {
        '&::placeholder': {
            opacity: 1,
            color: palette.text.secondary
        }
    }
};
