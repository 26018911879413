import React from 'react';
import { createClient, ClientContextProvider, useMutation, useQuery } from 'react-fetching-library';
import { trackException } from 'src/utils/appInsights';
import logger from 'src/utils/logger';
import { useSelector } from 'react-redux';
import parseErrorObject from './httpClientErrorParser';
import { toast } from '../components/Toast/Toast';

const requestAuthHeadersInterceptor = () => async (action) => {
    logger.info({ ...action, jsonBody: JSON.stringify(action?.body) });

    return {
        ...action
    };
};

const responseInterceptor = () => async (action, response) => {
    const { payload, status, headers: _headers, error, ...rest } = response;

    const headers = {};

    const it = _headers?.entries();
    let result = it?.next();

    while (!result.done) {
        const headerName = result.value[0];
        const headerValue = result.value[1];
        headers[headerName] = headerValue;
        result = it.next();
    }

    if (error && !action.disableErrorToast) {
        let errorMessages = parseErrorObject({ payload, status });
        if (errorMessages === '') {
            errorMessages = `Error with status ${status} occurred`;
        }

        toast.error(errorMessages);
        trackException(errorMessages);

        const interceptorResponse = { payload, status, action, headers, error, ...rest };
        logger.error('Error: ', interceptorResponse);

        return interceptorResponse;
    }

    const interceptorResponse = { ...response, action, headers, payload: status === 204 ? undefined : payload };
    logger.info(interceptorResponse);
    return interceptorResponse;
};

const client = createClient({
    requestInterceptors: [requestAuthHeadersInterceptor],
    responseInterceptors: [responseInterceptor]
});

const HttpClientProvider = ({ children }) => {
    return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};

const useHttpRequestHeaders = () => {
    const { claims, accessToken } = useSelector((state) => state.auth);

    return React.useMemo(() => ({
        Authorization: `Bearer ${accessToken}`,
        'Ocp-Apim-Subscription-Key': claims?.sysAdminSubscriptionKey ?? ''
    }), [accessToken, claims]);
};

export const buildEndpointUrlFromParams = (endpointUrl, params) => {
    return endpointUrl + (params && Object.keys(params).length > 0 ? (`?${Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')}`) : '');
};

const useHttpRequest = (requestConfig) => {
    const [requestInProgress, setRequestInProgress] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const headers = useHttpRequestHeaders();

    const { payload, mutate: _mutate, error, reset, abort: _abort } = useMutation((data) => {
        const _requestConfig = requestConfig(data);
        const endpoint = buildEndpointUrlFromParams(_requestConfig.endpoint, _requestConfig.params);

        return {
            ..._requestConfig,
            endpoint,
            headers: {
                ..._requestConfig.headers,
                ...headers
            }
        };
    });

    const mutate = React.useCallback(_mutate, [headers]);
    const abort = React.useCallback(_abort, []);

    React.useEffect(() => {
        let mounted = true;

        const makeRequest = async () => {
            const { data, resolve } = requestInProgress;
            const resp = await mutate(data);
            if (mounted) {
                setLoading(false);
                setRequestInProgress(null);
            }
            resolve(resp);
        };
        if (mounted && requestInProgress) {
            makeRequest();
        }

        return () => {
            mounted = false;
        };
    }, [requestInProgress, mutate]);

    // Abort requests on component dismount
    React.useEffect(() => () => abort(), [abort]);

    const mutateFunction = React.useCallback((data) => {
        setLoading(true);
        return new Promise((resolve) => { setRequestInProgress({ data, resolve }); });
    }, []);

    return { loading, payload, mutate: mutateFunction, error, reset, abort };
};

const useHttpGetRequest = (requestConfig, initFetch = true) => {
    const [queryTriggered, triggerQuery] = React.useState(false);
    const [loading, setLoading] = React.useState(initFetch);
    const headers = useHttpRequestHeaders();

    const endpoint = buildEndpointUrlFromParams(requestConfig.endpoint, requestConfig.params);

    const config = {
        ...requestConfig,
        endpoint,
        method: 'GET',
        headers: {
            ...requestConfig.headers,
            ...headers
        }
    };

    const { query: _query, abort: _abort, ...rest } = useQuery(config, false);

    const query = React.useCallback(_query, [config]);
    const abort = React.useCallback(_abort, []);

    const runQuery = React.useCallback(() => {
        setLoading(true);

        // Before triggering new request, abort previous request if its still running
        abort();
        return new Promise((resolve) => { triggerQuery({ resolve }); });
    }, [abort]);

    React.useEffect(() => {
        let mounted = true;

        const fetchData = async (resolve) => {
            const resp = await query(config);
            if (resp.error && resp?.errorObject?.code === 20) {
                // User aborted so new request is still loading, dont set loading to false
                logger.info('User aborted previous request');
            } else {
                // User did not abort
                setLoading(false);
            }
            resolve(resp);
        };

        if (mounted && queryTriggered) {
            const { resolve } = queryTriggered;
            triggerQuery(null);
            fetchData(resolve);
        }

        return () => {
            mounted = false;
        };
    }, [config, queryTriggered, query]);

    // Trigger query if endpoint changes eg. if parameters change when filtering
    React.useEffect(() => {
        let mounted = true;

        if (mounted && initFetch) {
            runQuery();
        }

        return () => {
            mounted = false;
        };
    }, [initFetch, endpoint, runQuery]);

    // Abort requests on component dismount
    React.useEffect(() => () => abort(), [abort]);

    return { ...rest, query: runQuery, loading, abort };
};

const useHttpFetchRequest = (requestConfig) => {
    const headers = useHttpRequestHeaders();
    const [requestInProgress, setRequestInProgress] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const getConfig = React.useCallback(requestConfig, []);

    React.useEffect(() => {
        let mounted = true;

        const makeRequest = async () => {
            const { data, resolve } = requestInProgress;

            const _requestConfig = getConfig(data);

            const request = {
                ..._requestConfig,
                endpoint: buildEndpointUrlFromParams(`${_requestConfig.endpoint}`, _requestConfig.params),
                headers: {
                    ..._requestConfig.headers,
                    ...headers
                }
            };

            if (mounted) { setLoading(true); }

            try {
                logger.info(request);
                const resp = await fetch(request.endpoint, request);
                let payload = await resp.text();

                try {
                    payload = JSON.parse(payload);
                    // eslint-disable-next-line no-empty
                } catch (error) { }

                const error = !resp.ok;
                let response = {};

                if (error) {
                    response = { status: resp?.status, error, errorMessages: parseErrorObject({ payload, status: resp?.status }), payload };
                } else {
                    response = { status: resp?.status, error, payload };
                }

                logger.info(response);
                resolve(response);
            } catch (error) {
                logger.error(error);
                setError(true);
                resolve({ error: true, payload: null });
            }

            if (mounted) {
                setLoading(false);
                setRequestInProgress(null);
            }
        };
        if (requestInProgress) {
            makeRequest();
        }

        return () => { mounted = false; };
    }, [requestInProgress, getConfig, headers]);

    const mutateFunction = (data) => {
        return new Promise((resolve) => { setRequestInProgress({ data, resolve }); });
    };

    return { loading, mutate: mutateFunction, error };
};

const QueryContext = React.createContext();

const QueryContextProvider = ({ value, children }) => {
    return <QueryContext.Provider value={value}>{children}</QueryContext.Provider>;
};

export const useQueryContext = () => {
    const context = React.useContext(QueryContext);
    if (context === undefined) {
        throw new Error('useQueryContext must be used within a QueryContextProvider');
    }
    return context;
};

export {
    HttpClientProvider,
    useHttpRequestHeaders,
    useHttpRequest,
    useHttpGetRequest,
    useHttpFetchRequest,

    QueryContextProvider
};