import React from 'react';
import { CustomerContextProvider } from 'src/scenes/Customers/services/customerContext';
import { CountryContextProvider } from 'src/scenes/Countries/services/context';
import { UserContextProvider } from 'src/scenes/Users/services/identityUserContext';
import { MessagingContextProvider } from 'src/scenes/Messaging/services/messagingContext';
import { MetadataContextProvider } from 'src/components/Metadata/metadataContext';
import { ReportingContextProvider } from 'src/scenes/Reporting/services/reportingContext';
import MessagingLogFilterContext from 'src/scenes/MessagingLog/components/MessagingLogFilterContext';
import DialogProvider from 'src/components/Dialog/DialogProvider';

const Contexts = ({ children }) => {
    return (
        <MetadataContextProvider>
            <UserContextProvider>
                <CustomerContextProvider>
                    <CountryContextProvider>
                        <MessagingContextProvider>
                            <ReportingContextProvider>
                                <MessagingLogFilterContext>
                                    <DialogProvider>
                                        {children}
                                    </DialogProvider>
                                </MessagingLogFilterContext>
                            </ReportingContextProvider>
                        </MessagingContextProvider>
                    </CountryContextProvider>
                </CustomerContextProvider>
            </UserContextProvider>
        </MetadataContextProvider>
    );
};

export default Contexts;