import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ServiceWorkerUpdateAvailable } from 'src/actions/appActions';
import store from 'src/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

serviceWorkerRegistration.register({
    onSuccess: () => { /* Not yet used */ },
    onUpdate: () => store.dispatch(ServiceWorkerUpdateAvailable()),
});
