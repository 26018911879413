import React from 'react';
import { brokerTableColumns, connectorTableColumns, messagingTemplatesTableColumns } from './columnDefinitions';

const MessagingStateContext = React.createContext();
const MessagingDispatchContext = React.createContext();

const defaultState = {
    adBanTablePagination: {
        take: 50,
        sortField: 'msisdn',
        sortOrder: 'asc',
        top: 0,
        page: 1
    },

    messagingTemplatesTableColumns,
    messagingTemplatesTablePagination: {
        take: 50,
        sortField: 'displayName',
        sortOrder: 'asc',
        top: 0,
        page: 1
    },

    brokerCreated: null,
    connectorCreated: null,
    brokerTableColumns,

    brokerTableFilters: {
        offset: 0,
        limit: 10,
        page: 1,

        name: ''
    },
    brokerTableSorting: {
        sortOrder: 'asc',
        sortField: 'displayName'
    },

    connectorTableColumns,

    connectorTableFilters: {
        offset: 0,
        limit: 10,
        page: 1,

        name: ''
    },
    connectorTableSorting: {
        sortOrder: 'asc',
        sortField: 'id'
    },

    appNames: [],
    brokerOptions: [],

    selectedRoutingTableTab: 'info',
    selectedRoutingTable: null,
    routingTablesChanged: false,
    selectedRoutingTableUpdated: false
};

function MessagingReducer(state, action) {
    switch (action.type) {
        case 'brokerCreated': {
            return {
                ...state,
                brokerCreated: { ...action.data }
            };
        }
        case 'brokerTableColumnToggled': {
            const columns = state.brokerTableColumns.map((column) => {
                if (column.dataField === action.data) {
                    return { ...column, hidden: !column.hidden };
                }
                return column;
            });

            return {
                ...state,
                brokerTableColumns: [...columns]
            };
        }
        case 'brokerTableFiltersChanged': {
            return {
                ...state,
                brokerTableFilters: { ...state.brokerTableFilters, ...action.data }
            };
        }
        case 'brokerTableSortingChanged': {
            return {
                ...state,
                brokerTableSorting: { ...state.brokerTableSorting, ...action.data }
            };
        }
        case 'connectorCreated': {
            return {
                ...state,
                connectorCreated: { ...action.data }
            };
        }
        case 'connectorTableColumnToggled': {
            const columns = state.connectorTableColumns.map((column) => {
                if (column.dataField === action.data) {
                    return { ...column, hidden: !column.hidden };
                }
                return column;
            });

            return {
                ...state,
                connectorTableColumns: [...columns]
            };
        }
        case 'connectorTableFiltersChanged': {
            return {
                ...state,
                connectorTableFilters: { ...state.connectorTableFilters, ...action.data }
            };
        }
        case 'connectorTableSortingChanged': {
            return {
                ...state,
                connectorTableSorting: { ...state.connectorTableSorting, ...action.data }
            };
        }
        case 'setAppNames': {
            return {
                ...state,
                appNames: action.data.map((d) => { return { label: d, value: d }; })
            };
        }
        case 'setBrokerOptions': {
            return {
                ...state,
                brokerOptions: action.data.map((broker) => { return { label: broker.displayName, value: broker.id }; })
            };
        }
        case 'selectRoutingTableTab': {
            return {
                ...state,
                selectedRoutingTableTab: action.data
            };
        }
        case 'setSelectedRoutingTable': {
            return {
                ...state,
                selectedRoutingTable: action.data
            };
        }
        case 'routingTablesChanged': {
            return {
                ...state,
                routingTablesChanged: !state.routingTablesChanged
            };
        }
        case 'selectedRoutingTableUpdated': {
            return {
                ...state,
                selectedRoutingTableUpdated: !state.selectedRoutingTableUpdated
            };
        }
        case 'updateAdBanTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                adBanTablePagination: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        case 'updateMessagingTemplatesTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                messagingTemplatesTablePagination: {
                    take,
                    sortField,
                    sortOrder,
                    top,
                    page
                }
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const MessagingContextProvider = ({ children }) => {
    const [state, updateContext] = React.useReducer(MessagingReducer, defaultState);
    return (
        <MessagingStateContext.Provider value={state}>
            <MessagingDispatchContext.Provider value={updateContext}>
                {children}
            </MessagingDispatchContext.Provider>
        </MessagingStateContext.Provider>
    );
};

function useMessagingState() {
    const context = React.useContext(MessagingStateContext);
    if (context === undefined) {
        throw new Error('useMessagingState must be used within a MessagingProvider');
    }
    return context;
}

function useMessagingDispatch() {
    const context = React.useContext(MessagingDispatchContext);
    if (context === undefined) {
        throw new Error('useMessagingDispatch must be used within a MessagingProvider');
    }
    return context;
}

export {
    MessagingContextProvider,
    useMessagingState,
    useMessagingDispatch
};