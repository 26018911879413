import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, ButtonGroup, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LockIcon from '@material-ui/icons/LockRounded';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        '&& $lockIcon': {
            marginRight: 11
        }
    },
    nonCollapsableMenuItemButton: {
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    collapsableMenuItem: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    collapsableMenuItemButton: {
        border: 0,
        padding: '10px 8px',
        textTransform: 'none',
        letterSpacing: 0,
        flex: 1,
        justifyContent: 'flex-start'
    },
    collapsableMenuItemExpandButton: {
        border: 0,
        padding: 0
    },
    buttonLeaf: {
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        /* '&.depth-0': {
            fontWeight: theme.typography.fontWeightMedium
        } */
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    expandIcon: {
        marginLeft: 'auto',
        height: 16,
        width: 16
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    active: {
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeightBold,
        '& $icon': {
            color: theme.palette.white
        }
    },
    navItemTitle: {
        flexGrow: 1
    },
    lockIcon: {
        fontSize: 18,
        marginRight: 3
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.white,
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const LockedNavitemIcon = () => {
    const classes = useStyles();

    return (
        <HtmlTooltip
            placement="right"
            title={(
                <Typography variant="h5">Contact sales to activate the service</Typography>
            )}
        >
            <LockIcon className={classes.lockIcon} />
        </HtmlTooltip>
    );
};

const NavItem = ({
    disabled,
    title,
    titleComponent: TitleComponent,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp = false,
    label: Label,
    linkable,
    ...rest
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    // Open parent if child becomes active from navigation
    React.useEffect(() => {
        setOpen(openProp);
    }, [openProp]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {
        paddingLeft
    };

    if (children) {
        return (
            <ListItem
                {...rest}
                disabled={disabled}
                className={clsx(classes.item, className)}
                disableGutters
            >
                {linkable ? (
                    <ButtonGroup className={classes.collapsableMenuItem}>
                        <Button
                            disabled={disabled}
                            className={classes.collapsableMenuItemButton}
                            onClick={() => { }}
                            style={style}
                            activeClassName={disabled ? '' : classes.active}
                            component={RouterLink}
                            exact={!linkable}
                            to={href}
                        >
                            {Icon && <Icon className={classes.icon} />}
                            {TitleComponent ? <TitleComponent /> : title}
                        </Button>
                        <Button
                            disabled={disabled}
                            className={classes.collapsableMenuItemExpandButton}
                            onClick={handleToggle}
                        >
                            {!disabled && open && <ExpandLessIcon color="inherit" />}
                            {!disabled && !open && <ExpandMoreIcon color="inherit" />}
                        </Button>
                    </ButtonGroup>
                ) :
                    (
                        <Button
                            disabled={disabled}
                            className={classes.nonCollapsableMenuItemButton}
                            style={style}
                            onClick={handleToggle}
                        >
                            {Icon && <Icon className={classes.icon} />}
                            {TitleComponent ? <TitleComponent /> : title}
                            <div style={{ flexGrow: 1 }} />
                            {disabled && <LockedNavitemIcon />}
                            {!disabled && open && <ExpandLessIcon color="inherit" />}
                            {!disabled && !open && <ExpandMoreIcon color="inherit" />}
                        </Button>
                    )}
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem
            {...rest}
            disabled={disabled}
            className={clsx(classes.itemLeaf, className)}
            disableGutters
        >
            <Button
                disabled={disabled}
                activeClassName={disabled ? '' : classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                component={RouterLink}
                exact={false}
                style={style}
                to={href}
                onClick={(e) => { e.currentTarget.blur(); }}
            >
                {Icon && <Icon className={classes.icon} />}
                <span className={classes.navItemTitle}>
                    {TitleComponent ? <TitleComponent /> : title}
                </span>
                {Label && (
                    <span className={classes.label}>
                        <Label />
                    </span>
                )}
            </Button>
            {disabled && <LockedNavitemIcon />}
        </ListItem>
    );
};

NavItem.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    label: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string,
    titleComponent: PropTypes.func,
    linkable: PropTypes.bool
};

export default NavItem;
