import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    pageRoot: {
        padding: theme.spacing(2, 2, 2, 2),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 1, 0, 1)
        },
        overflowX: 'hidden',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        'body *#lg_chat_container &': {
            marginBottom: 60
        }
    }
}));

const Page = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.pageRoot}>
            {children}
        </div>
    );
};

Page.propTypes = {
    children: PropTypes.node
};

export default Page;
