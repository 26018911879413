import { BarChartRounded, BuildRounded, NotificationsRounded, PermIdentityRounded, SettingsRounded, TextsmsRounded, TuneRounded } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import PeopleRounded from '@material-ui/icons/PeopleRounded';

export default [
    {
        subheader: '',
        items: [
            {
                title: 'Dashboard',
                href: '/dashboard',
                icon: DashboardIcon,
                linkable: false
            },
            {
                title: 'Customers',
                href: '/customermanagement',
                icon: PeopleRounded,
                linkable: false,
                items: [
                    {
                        title: 'Customers',
                        href: '/customermanagement/customers',
                        linkable: true
                    },
                    {
                        title: 'Deleted Customers',
                        href: '/customermanagement/deletedcustomers',
                        linkable: true
                    },
                    {
                        title: 'Countries',
                        href: '/customermanagement/countries',
                        linkable: true
                    },
                    {
                        title: 'Purchases',
                        href: '/customermanagement/purchases',
                        linkable: true
                    },
                    {
                        title: 'Users',
                        href: '/customermanagement/users',
                        linkable: true
                    }
                ]
            },
            {
                title: 'Messaging',
                href: '/messagingmanagement',
                icon: TuneRounded,
                items: [
                    {
                        title: 'AdBan',
                        href: '/messagingmanagement/adban'
                    },
                    {
                        title: 'Brokers',
                        href: '/messagingmanagement/brokers'
                    },
                    {
                        title: 'Connectors',
                        href: '/messagingmanagement/connectors'
                    },
                    {
                        title: 'Kannel',
                        href: '/messagingmanagement/kannel'
                    },
                    {
                        title: 'Message Blocking',
                        href: '/messagingmanagement/messageblocking'
                    },
                    {
                        title: 'Input Sources',
                        href: '/messagingmanagement/inputsources'
                    },
                    {
                        title: 'OpCheck',
                        href: '/messagingmanagement/opcheck'
                    },
                    {
                        title: 'Pricing Tables',
                        href: '/messagingmanagement/pricingtables'
                    },
                    {
                        title: 'Routing Tables',
                        href: '/messagingmanagement/routingtables'
                    },
                    {
                        title: 'Templates',
                        href: '/messagingmanagement/templates'
                    }
                ]
            },
            {
                title: 'News & Alerts',
                href: '/news-alerts',
                icon: NotificationsRounded,
                items: [
                    {
                        title: 'Mangement',
                        href: '/news-alerts'
                    }
                ]
            },
            {
                title: 'Reporting',
                href: '/reporting',
                icon: BarChartRounded,
                items: [
                    {
                        title: 'Configurations',
                        href: '/reporting/configurations'
                    },
                    {
                        title: 'Countries',
                        href: '/reporting/countries'
                    },
                    {
                        title: 'Premium Broker Pricing MT',
                        href: '/reporting/premiumbrokerpricingmt'
                    },
                    {
                        title: 'Reports',
                        href: '/reporting/reports'
                    }
                ]
            },
            {
                title: 'Services',
                href: '/services',
                icon: SettingsRounded,
                items: [
                    {
                        title: 'Redeemable Codes',
                        href: '/services/redeemablecodes'
                    }
                ]
            },
            {
                title: 'Logging',
                href: '/log',
                icon: TextsmsRounded,
                items: [
                    {
                        title: 'Message Log',
                        href: '/log/messages'
                    },
                    {
                        title: 'Audit Log',
                        href: '/log/audit'
                    }
                ]
            },
            {
                title: 'Utils',
                href: '/utils',
                icon: BuildRounded,
                items: [
                    {
                        title: 'Account Authentication',
                        href: '/utils/accountauthentication'
                    },
                    {
                        title: 'Config Generation',
                        href: '/utils/configgeneration'
                    },
                    {
                        title: 'Opaali Credentials',
                        href: '/utils/opaalicredentials'
                    },
                    {
                        title: 'Swagger',
                        href: '/utils/swagger'
                    }
                ]
            },
            {
                title: 'User Access',
                href: '/useraccess',
                icon: PermIdentityRounded,
                items: [
                    {
                        title: 'Product Bundles',
                        href: '/useraccess/productbundles'
                    }
                ]
            }
        ]
    }
];