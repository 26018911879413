import React from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';

const useAccessToken = (scopes) => {
    if (!scopes) {
        throw new Error('useAccessToken - scopes parameter not provided');
    }

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = React.useState(null);

    React.useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None && accounts?.[0]) {
            const request = {
                account: accounts[0],
                scopes
            };
            instance.acquireTokenSilent(request).then((response) => {
                setAccessToken(response.accessToken);
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(request);
                }
                return null;
            });
        }
    }, [inProgress, isAuthenticated, accounts, instance, scopes]);

    return accessToken;
};

export default useAccessToken;