import { actions } from 'src/actions/signalrActions';

const initialState = {
    connection: null,
    connected: false,
    reconnecting: false,
    error: false,

    connectedOn: null,
    disconnectedOn: null
};

const signalRReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actions.CONNECTING: {
            return {
                ...state,
                connection: action?.connection ?? null
            };
        }
        case actions.CONNECTED: {
            return {
                ...state,
                connected: true,
                connection: action.data?.connection ?? null,
                connectedOn: new Date(),
                disconnectedOn: null,
                error: false
            };
        }
        case actions.RECONNECTING: {
            return {
                ...state,
                connected: false,
                reconnecting: true
            };
        }
        case actions.ERROR: {
            return {
                ...state,
                connected: false,
                error: true,
                reconnecting: false
            };
        }
        case actions.DISCONNECTED: {
            return {
                ...state,
                connected: false,
                connectedOn: null,
                disconnectedOn: new Date()
            };
        }
        case actions.CONNECTION_STOPPED: {
            return {
                ...state,
                connected: false,
                connection: null
            };
        }
        default: {
            return state;
        }
    }
};

export default signalRReducer;
