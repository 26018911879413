import Configuration from './Configuration.md';
import PricingMT from './PricingMT.md';
import PricingHistoryMT from './PricingHistoryMT.md';
import PremiumPricingMT from './PremiumPricingMT.md';
import PremiumPricingExceptions from './PremiumPricingExceptions.md';
import PremiumPricingMTHistory from './PremiumPricingMTHistory.md';
import BrokerPricings from './BrokerPricings.md';
import BrokerPricingHistory from './BrokerPricingHistory.md';
import WhatsappPricing from './WhatsappPricing.md';

export default [
    { path: '/reporting/configurations/:customerId/general', content: Configuration },
    { path: '/reporting/configurations/:customerId/pricing', content: PricingMT },
    { path: '/reporting/configurations/:customerId/pricinghistory', content: PricingHistoryMT },
    { path: '/reporting/configurations/:customerId/premiumpricing', content: PremiumPricingMT },
    { path: '/reporting/premiumbrokerpricingmt', content: PremiumPricingExceptions },
    { path: '/reporting/configurations/:customerId/premiumpricinghistory', content: PremiumPricingMTHistory },
    { path: '/reporting/configurations/:customerId/brokerpricing', content: BrokerPricings },
    { path: '/reporting/configurations/:customerId/brokerpricinghistory', content: BrokerPricingHistory },
    { path: '/reporting/configurations/:customerId/whatsapp', content: WhatsappPricing }
];