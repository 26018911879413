const parseErrorMessages = (errorObj) => {
    let errors = [];

    if (typeof (errorObj) === 'string') errors.push(errorObj);

    else if (Array.isArray(errorObj)) {
        errorObj.forEach((err) => {
            errors = [
                ...errors,
                ...parseErrorMessages(err)
            ];
        });
    } else if (typeof (errorObj) === 'object') {
        if (errorObj.errorMessage) {
            errors.push(errorObj.errorMessage);
        }
    }

    return errors;
};

const parseValidationFailures = (errorObj) => {
    return errorObj?.validationFailures?.filter?.((vf) => (vf.propertyName && vf.errorMessage)) ?? [];
};

const parseErrorObject = ({ payload, status }) => {
    const validationFailures = parseValidationFailures(payload);
    const formattedValidationFailures = validationFailures?.map((vf) => `${vf?.propertyName} ${vf?.errorMessage}`)?.filter((vf) => vf && vf?.trim?.() !== '').join('\n') ?? '';

    const errorMessages = parseErrorMessages(payload);
    const formattedErrorMessages = errorMessages?.join('\n') ?? '';

    if (formattedValidationFailures !== '') {
        return `${formattedErrorMessages}\n${formattedValidationFailures}`;
    }

    if (formattedErrorMessages === '' && status === 403) {
        return 'This action requires Authorization';
    }

    return formattedErrorMessages;
};

export default parseErrorObject;