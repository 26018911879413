import React from 'react';

const useTabHasFocus = () => {
    const [tabHasFocus, setTabHasFocus] = React.useState(true);

    React.useEffect(() => {
        const handleFocus = () => {
            setTabHasFocus(true);
        };

        const handleBlur = () => {
            setTabHasFocus(false);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return tabHasFocus;
};

export default useTabHasFocus;