import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';

const DialogContext = React.createContext();

export const useDialogContext = () => React.useContext(DialogContext);

const DialogContainer = ({
    RawContent,
    Title,
    Content,
    Actions,
    closeOnClickAway = false,
    maxWidth: initialMaxWidth = 'sm',
    fullWidth = true,
    open,
    onClose,
    internalClose,
    onExited,
    ...rest
}) => {
    const [maxWidth, setMaxWidth] = React.useState(initialMaxWidth);
    return (
        <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open={open} onClose={onClose ?? (closeOnClickAway ? internalClose : null)} TransitionProps={{ onExited }}>
            {RawContent && <RawContent {...rest} close={internalClose} maxWidth={maxWidth} setMaxWidth={setMaxWidth} />}
            {!RawContent && (
                <>
                    <DialogTitle>{Title}</DialogTitle>
                    <DialogContent>{Content}</DialogContent>
                    <DialogActions>{Actions}</DialogActions>
                </>
            )}
        </Dialog>
    );
};

const DialogProvider = ({ children }) => {
    const [dialogs, setDialogs] = React.useState([]);

    const createDialog = React.useCallback((option) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    }, []);

    const closeDialog = React.useCallback((index) => {
        setDialogs((dialogs) => {
            const _dialogs = [...dialogs];

            if (_dialogs.splice(index).length === 0) {
                _dialogs.pop();
            }
            return _dialogs;
        });
    }, []);

    const contextValue = React.useRef([createDialog, closeDialog]).current;

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {dialogs.map((dialog, i) => {
                // eslint-disable-next-line react/no-array-index-key
                return <DialogContainer key={i} {...dialog} internalClose={() => closeDialog(i)} />;
            })}
        </DialogContext.Provider>
    );
};

export default DialogProvider;