const countriesColumns = [
    /* {
        dataField: "id",
        text: "Id",
        sort: true
    }, */
    {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'language',
        text: 'Language',
        sort: true
    },
    {
        dataField: 'currency',
        text: 'Currency',
        sort: true
    },
    {
        dataField: 'alpha2Code',
        text: 'Alpha 2',
        sort: true
    },
    {
        dataField: 'alpha3Code',
        text: 'Alpha 3',
        sort: true
    },
    {
        dataField: 'utcOffset',
        text: 'UtcOffset',
        sort: true
    },
    {
        dataField: 'supportsDaylightSavingTime',
        text: 'Supports DST',
        sort: true
    }
];

export { countriesColumns };