import React from 'react';
import { Button, PopoverBody, PopoverHeader, Popover, List } from 'reactstrap';
import momentDateParser from 'src/utils/momentDateParser';
import { v4 as UuidV4 } from 'uuid';

export const momentDateFormatter = (cell) => {
    const date = momentDateParser(cell);
    return cell && date.isValid() ? date.format('DD.MM.YYYY') : '';
};

export const utcMomentDateFormatter = (cell) => {
    const date = momentDateParser(cell, true);
    return cell && date.isValid() ? date.format('DD.MM.YYYY') : '';
};

export const preciseMomentDateFormatter = (cell) => {
    const date = momentDateParser(cell);
    return cell && date.isValid() ? date.format('DD.MM.YYYY HH.mm.ss') : '';
};

export const booleanFormatter = (cell) => (cell ? 'Yes' : 'No');

export const enumFormatter = (cell, row, rowIndex, enumValues) => {
    if (enumValues && enumValues.length > 0) {
        const match = enumValues.find((fe) => fe.value === cell.toString());
        if (match) { return match.label; }
        return '';
    }
    return '';
};

export const customerIdFormatter = (cell, row, rowIndex, customers) => {
    if (customers && customers.length > 0) {
        const match = customers.find((c) => c.value === cell);
        if (match) { return match.label; }
        return cell;
    }
    return cell;
};

export const ContactInformationPopover = ({ infos, popoverTitle }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [popoverId] = React.useState(UuidV4());

    const handleShowContactInfos = (e) => {
        e.preventDefault();
        e.currentTarget.blur();
        setIsOpen(true);
    };

    const handleCloseClick = (e) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const buttonId = `contactInformationPopover_${popoverId}`;

    return (
        <>
            <Button id={buttonId} color="link" style={{ padding: 0 }} onClick={handleShowContactInfos}>{`${infos?.length} contacts`}</Button>
            <Popover trigger="focus" target={buttonId} placement="bottom" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <PopoverHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{popoverTitle}</span>
                    <Button close onClick={handleCloseClick} />
                </PopoverHeader>
                <PopoverBody>
                    <List type="unstyled">
                        {infos?.map((email) => (
                            <li key={email}>{email}</li>
                        ))}
                    </List>
                </PopoverBody>
            </Popover>
        </>
    );
};

export const customerContactInformationFormatter = (cell, popoverTitle) => {
    const infos = cell?.map((ci) => (ci?.email ? ci?.email : 'No email address!')) ?? [];

    if (infos.length === 0) {
        return '';
    } if (infos.length === 1) {
        return infos[0];
    }
    return <ContactInformationPopover infos={infos} popoverTitle={popoverTitle} />;
};