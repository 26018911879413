import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParameters = (name) => {
    const location = useLocation();
    const history = useHistory();
    const [search] = React.useState(location.search);
    const searchParams = new URLSearchParams(search);
    let queryParams = null;

    if (Array.isArray(name)) {
        queryParams = name?.reduce((prev, current) => ({ ...prev, [current]: searchParams.get(current) }), {});
    } else if (typeof (name) === 'string') {
        queryParams = searchParams.get(name);
    }

    React.useEffect(() => {
        if (location.search !== '') {
            history.replace({ ...location, search: '' });
        }
    }, [location, history]);

    return queryParams;
};

const useQueryParam = (parameterName) => {
    const location = useLocation();
    const history = useHistory();

    const paramValue = React.useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(parameterName);
    }, [location.search, parameterName]);

    const setParamValue = React.useCallback((value) => {
        const queryParams = new URLSearchParams(location.search);
        const valueExists = queryParams.get(parameterName);
        if (valueExists && !value) {
            queryParams.delete(parameterName);
        } else if (value) {
            queryParams.set(parameterName, value);
        }
        history.replace({
            search: queryParams.toString()
        });
    }, [location.search, parameterName, history.replace]);

    return { set: setParamValue, value: paramValue };
};

export {
    useQueryParameters,
    useQueryParam
};