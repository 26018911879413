import React from 'react';
import { countriesColumns, useGetPremiumBrokerPricingExceptionsColumns } from './columnDefinitions';

const ReportingStateContext = React.createContext();
const ReportingDispatchContext = React.createContext();

const configurationListdefaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

const pricingsListdefaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

const whatsappPricingsListdefaultSorted = [{
    dataField: 'country.name',
    order: 'asc'
}];

const premiumPricingListdefaultSorted = [{
    dataField: 'brokerId',
    order: 'asc'
}];

const premiumPricingHistoryListdefaultSorted = [{
    dataField: 'brokerId',
    order: 'asc'
}];

const pricingHistoryListDefaultSorted = [{
    dataField: 'changeDate',
    order: 'asc'
}];

const brokerPricingListdefaultSorted = [{
    dataField: 'brokerId',
    order: 'desc'
}];

const brokerPricingHistoryListDefaultSorted = [{
    dataField: 'startDate',
    order: 'desc'
}];

const defaultState = {
    configurations: {
        missingIncompleteConfigsModal: {
            open: false,
            includeInactiveConfigs: true,
            validateBrokerPricing: true,
            validateWhatsappPricing: true,
            pricingProtocols: ['SMS', 'MMS', 'Whatsapp', 'RCS'],
            activeTab: '1'
        },
        customers: [],
        customersLoading: false,
        configurations: [],
        configurationsLoading: false,

        configurationListDefaultSorted: configurationListdefaultSorted,
        configurationListPagination: {
            take: 10,
            sortField: 'customerName',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        pricingsListDefaultSorted: pricingsListdefaultSorted,
        pricingsListPagination: {
            take: 10,
            sortField: 'pricingType',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        whatsappPricingsListDefaultSorted: whatsappPricingsListdefaultSorted,
        whatsappPricingsListPagination: {
            take: 10,
            sortField: 'country.name',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        premiumPricingListDefaultSorted: premiumPricingListdefaultSorted,
        premiumPricingListPagination: {
            take: 10,
            sortField: 'brokerId',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        premiumPricingHistoryListDefaultSorted: premiumPricingHistoryListdefaultSorted,
        premiumPricingHistoryListPagination: {
            take: 10,
            sortField: 'brokerId',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        pricingHistoryListDefaultSorted,
        pricingHistoryListPagination: {
            take: 10,
            sortField: 'changeDate',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        brokerPricingListDefaultSorted: brokerPricingListdefaultSorted,
        brokerPricingListPagination: {
            take: 10,
            sortField: 'brokerId',
            sortOrder: 'desc',
            top: 0,
            page: 1
        },
        brokerPricingHistoryListDefaultSorted,
        brokerPricingHistoryListPagination: {
            take: 10,
            sortField: 'startDate',
            sortOrder: 'desc',
            top: 0,
            page: 1
        },
        missingConfigurationTablePagination: {
            take: 10,
            sortField: 'customerName',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        incompleteConfigurationTablePagination: {
            take: 10,
            sortField: 'customerName',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        inactiveConfigurationTablePagination: {
            take: 10,
            sortField: 'customerName',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
        configurationSelectedActiveTab: 'settings',
        configurationSelected: null,
        configurationUpdate: false,
        selectedCustomers: []
    },
    countries: {
        columnDefinitions: countriesColumns,

        tablePaging: {
            take: 10,
            sortField: 'name',
            sortOrder: 'asc',
            top: 0,
            page: 1
        },
    },
    premiumBrokerPricingExceptions: {
        useGetColumnDefinitions: useGetPremiumBrokerPricingExceptionsColumns,

        tablePaging: {
            take: 10,
            sortField: 'brokerId',
            sortOrder: 'desc',
            top: 0,
            page: 1
        },
    },
    reports: {
        reportType: 'broker'
    }
};

function reportingReducer(state, action) {
    switch (action.type) {
        case 'updateMissingIncompleteConfigsModal': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    missingIncompleteConfigsModal: {
                        ...state.configurations.missingIncompleteConfigsModal,
                        ...action.data
                    }
                }
            };
        }
        case 'toggleMissingIncompleteConfigsModal': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    missingIncompleteConfigsModal: {
                        ...state.configurations.missingIncompleteConfigsModal,
                        open: !state.configurations.missingIncompleteConfigsModal.open
                    }
                }
            };
        }
        case 'setCustomers': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    customers: action.data
                }
            };
        }
        case 'setCustomersLoading': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    customersLoading: action.data
                }
            };
        }
        case 'setConfigurations': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurations: action.data
                }
            };
        }
        case 'setConfigurationsLoading': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurationsLoading: action.data
                }
            };
        }
        case 'updateCountryPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                countries: {
                    ...state.countries,
                    tablePaging: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updatePremiumBrokerPricingExceptionsTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                premiumBrokerPricingExceptions: {
                    ...state.premiumBrokerPricingExceptions,
                    tablePaging: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateConfigurationListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurationListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updatePricingsListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    pricingsListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateWhatsappPricingsListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    whatsappPricingsListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updatePremiumPricingListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    premiumPricingListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updatePremiumPricingHistoryListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    premiumPricingHistoryListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updatePricingHistoryListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    pricingHistoryListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateBrokerPricingListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    brokerPricingListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateBrokerPricingHistoryListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    brokerPricingHistoryListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'configurationSelectedActiveTab': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurationSelectedActiveTab: action.data
                }
            };
        }
        case 'configurationSelected': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurationSelected: action.data
                }
            };
        }
        case 'configurationUpdated': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    configurationUpdated: !state.configurations.configurationUpdated
                }
            };
        }
        case 'configurationSelectCustomers': {
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    selectedCustomers: action.data
                }
            };
        }

        case 'updateGenericReportListPagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                reports: {
                    ...state.reports,
                    genericReportListPagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'changeReportType': {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    reportType: action.data
                }
            };
        }
        case 'updateMissingConfigurationTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    missingConfigurationTablePagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateIncompleteConfigurationTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    incompleteConfigurationTablePagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        case 'updateInactiveConfigurationTablePagination': {
            const { take, sortField, sortOrder, top, page } = action.data;

            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    inactiveConfigurationTablePagination: {
                        take,
                        sortField,
                        sortOrder,
                        top,
                        page
                    }
                }
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const ReportingContextProvider = ({ children }) => {
    const [state, updateContext] = React.useReducer(reportingReducer, defaultState);
    return (
        <ReportingStateContext.Provider value={state}>
            <ReportingDispatchContext.Provider value={updateContext}>
                {children}
            </ReportingDispatchContext.Provider>
        </ReportingStateContext.Provider>
    );
};

function useConfigurationState() {
    const context = React.useContext(ReportingStateContext);
    const { configurations } = context;
    if (context === undefined) {
        throw new Error('useConfigurationState must be used within a ReportingProvider');
    }
    return configurations;
}

function useCountriesState() {
    const context = React.useContext(ReportingStateContext);
    const { countries } = context;
    if (context === undefined) {
        throw new Error('useCountriesState must be used within a ReportingProvider');
    }
    return countries;
}

function usePremiumBrokerExceptionsState() {
    const context = React.useContext(ReportingStateContext);
    const { premiumBrokerPricingExceptions } = context;
    if (context === undefined) {
        throw new Error('useCountriesState must be used within a ReportingProvider');
    }
    return premiumBrokerPricingExceptions;
}

function useReportsState() {
    const context = React.useContext(ReportingStateContext);
    const { reports } = context;
    if (context === undefined) {
        throw new Error('useReportsState must be used within a ReportingProvider');
    }
    return reports;
}

function useReportingDispatch() {
    const context = React.useContext(ReportingDispatchContext);
    if (context === undefined) {
        throw new Error('useReportingDispatch must be used within a ReportingProvider');
    }
    return context;
}

export {
    ReportingContextProvider,
    useConfigurationState,
    useCountriesState,
    usePremiumBrokerExceptionsState,
    useReportsState,
    useReportingDispatch
};