const navBarTheme = (theme) => ({
    ...theme,
    palette: {
        ...theme.palette,
        icon: {
            color: theme.palette.white
        }
    },
    overrides: {
        ...theme.overrides,
        input: {
            color: theme.palette.white
        },
        MuiSelect: {
            icon: {
                color: theme.palette.white,
                '&.Mui-disabled': {
                    color: theme.palette.white
                }
            },
            root: {
                color: theme.palette.white
            },
            selectMenu: {
                color: theme.palette.white
            }
        },
        MuiListItem: {
            root: {
                '&.Mui-disabled': {
                    '& *': {
                        color: theme.palette.background.lightGray
                    }
                }
            }
        },
        MuiButton: {
            root: {
                color: theme.palette.white,
                '&.Mui-disabled': {
                    '& *': {
                        color: theme.palette.background.lightGray
                    }
                },
                '&:hover': {
                    color: theme.palette.white,
                    cursor: 'pointer'
                }
            },
            outlined: {
                '&.Mui-disabled': {
                    border: 0
                }
            }
        },
        MuiInputLabel: {
            root: {
                color: theme.palette.white
            }
        },
        MuiInput: {
            root: {
                color: theme.palette.white
            },
            input: {
                color: theme.palette.white
            },
            underline: {
                '&:before': {
                    borderBottom: `1px solid ${theme.palette.white}`
                },
                '&:after': {
                    borderBottom: `2px solid ${theme.palette.primary.main}`
                },
                '&:hover:not($disabled):not($focused):not($error):before': {
                    borderBottom: `2px solid ${theme.palette.primary.main}`
                }
            }
        },
        MuiBottomNavigation: {
            root: {
                backgroundColor: theme.palette.menu
            }
        },
        MuiBottomNavigationAction: {
            root: {
                color: theme.palette.white,
                '&.Mui-selected': {
                    color: theme.palette.white,
                    fontWeight: theme.typography.fontWeightBold,

                },
                '&.Mui-disabled': {
                    '& *': {
                        color: theme.palette.tertiary.main
                    }
                }
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: theme.palette.background.default
            }
        },
        MuiDrawer: {
            root: {
                '& $paper': {
                    border: 0
                }
            }
        }
    }
});

export default navBarTheme;