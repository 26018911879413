import defaultTheme from '@material-ui/core/styles/defaultTheme';
import palette from '../palette';

export default {
    MuiDialogTitle: {
        root: {
            backgroundColor: palette.background.gray,
            '& > *': {
                color: palette.white
            }
        }
    },
    MuiDialogContent: {
        root: {
            overflowY: 'auto',
            [defaultTheme.breakpoints.down('xs')]: {
                padding: defaultTheme.spacing(1, 1)
            }
        }
    },
    MuiDialog: {
        paper: {
            overflowY: 'hidden'
        }
    }
};